* {
  box-sizing: border-box;
  line-height: 1.4em;
}
body {
  height: 100vh;
  font-size: 13px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
input,
textarea {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
textarea {
  line-height: 1.4em;
}
h1 {
  margin: 0px;
  font-weight: 400;
}
@keyframes moveAppIconUp {
  0% {
    margin-bottom: 0px;
  }
  100% {
    margin-bottom: 200px;
  }
}
.agtech-web-app {
  height: 100%;
  width: 100%;
}
.agtech-web-app .agtech-web-app-surface {
  height: 100%;
  width: 100%;
  opacity: 0;
  animation: fadeIn 400ms forwards 0ms;
  display: flex;
}
.agtech-web-app .agtech-web-app-loading-page-surface {
  background-color: #1c1c1c;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
}
.agtech-web-app .agtech-web-app-loading-page-surface .agtech-web-app-loading-page-content {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
}
.agtech-web-app .agtech-web-app-loading-page-surface .agtech-web-app-loading-page-content .agtech-web-app-loading-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  animation: moveAppIconUp 1000ms forwards 1200ms;
}
.agtech-web-app .agtech-web-app-loading-page-surface .agtech-web-app-loading-page-content .agtech-web-app-loading-logo .agtech-web-app-loading-icon {
  color: white;
  font-size: 68px;
  margin-bottom: 24px;
  opacity: 0;
  animation: fadeIn 1000ms forwards 0ms;
}
@media screen and (max-height: 600px) {
  .agtech-web-app .agtech-web-app-loading-page-surface .agtech-web-app-loading-page-content .agtech-web-app-loading-logo .agtech-web-app-loading-icon {
    font-size: 60px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-web-app .agtech-web-app-loading-page-surface .agtech-web-app-loading-page-content .agtech-web-app-loading-logo .agtech-web-app-loading-icon {
    font-size: 6vh;
  }
}
.agtech-web-app .agtech-web-app-loading-page-surface .agtech-web-app-loading-page-content .agtech-web-app-loading-logo .agtech-web-app-loading-name {
  color: white;
  font-size: 38px;
  opacity: 0;
  animation: fadeIn 1000ms forwards 2200ms;
  font-family: orbitron;
}
@media screen and (max-height: 600px) {
  .agtech-web-app .agtech-web-app-loading-page-surface .agtech-web-app-loading-page-content .agtech-web-app-loading-logo .agtech-web-app-loading-name {
    font-size: 34px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-web-app .agtech-web-app-loading-page-surface .agtech-web-app-loading-page-content .agtech-web-app-loading-logo .agtech-web-app-loading-name {
    font-size: 3.5vh;
  }
}
.agtech-web-app .agtech-web-app-loading-page-surface .agtech-web-app-loading-page-content .agtech-web-app-loading-body {
  flex-direction: column;
  color: white;
  opacity: 0;
  animation: fadeIn 1000ms forwards 2200ms;
  display: flex;
}
.agtech-web-app .agtech-web-app-loading-page-surface .agtech-web-app-loading-page-content .agtech-web-app-loading-body .agtech-web-app-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.agtech-web-app .agtech-web-app-loading-page-surface .agtech-web-app-loading-page-content .agtech-web-app-loading-body .agtech-web-app-loader .loader {
  border-top-color: white !important;
  border-left-color: white !important;
  margin-bottom: 28px;
}
.agtech-web-app .agtech-web-app-loading-page-surface .agtech-web-app-loading-page-content .agtech-web-app-loading-body .agtech-web-app-loader .agtech-web-app-loader-text {
  font-size: 17px;
}
.agtech-web-app .agtech-web-app-loading-page-surface .agtech-web-app-loading-page-content .agtech-web-app-loading-body .agtech-login-button {
  justify-content: center;
  color: #133463;
  font-weight: 600;
  font-size: 18px;
  padding: 12px 32px;
  width: 220px;
  border-radius: 6px;
  margin-bottom: 20px;
}
.agtech-web-app .agtech-web-app-loading-page-surface .agtech-web-app-loading-page-content .agtech-web-app-loading-body .agtech-register-button {
  justify-content: center;
  background-color: transparent;
  color: white;
  border-color: white;
  font-weight: 600;
  font-size: 18px;
  padding: 12px 32px;
  width: 220px;
  border-radius: 6px;
  margin-bottom: 16px;
}
.agtech-core-side-navigation-item {
  flex-direction: row;
  align-items: center;
  height: 56px;
  min-height: 56px;
  max-height: 56px;
  display: flex;
  text-decoration: none !important;
}
@media screen and (max-height: 600px) {
  .agtech-core-side-navigation-item {
    height: 56px;
    min-height: 56px;
    max-height: 56px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-core-side-navigation-item {
    height: 56px;
    min-height: 56px;
    max-height: 56px;
  }
}
.agtech-side-navigation-item {
  flex-direction: row;
  align-items: center;
  height: 56px;
  min-height: 56px;
  max-height: 56px;
  display: flex;
  text-decoration: none !important;
  font-size: 15px;
  padding-left: 28px;
  padding-right: 28px;
  cursor: pointer;
  color: white;
}
@media screen and (max-height: 600px) {
  .agtech-side-navigation-item {
    height: 56px;
    min-height: 56px;
    max-height: 56px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-side-navigation-item {
    height: 56px;
    min-height: 56px;
    max-height: 56px;
  }
}
@media screen and (max-height: 600px) {
  .agtech-side-navigation-item {
    font-size: 14px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-side-navigation-item {
    font-size: 16px;
  }
}
.agtech-side-navigation-item .agtech-side-navigation-item-icon {
  margin-right: 24px;
  font-size: 16px;
}
@media screen and (max-height: 600px) {
  .agtech-side-navigation-item .agtech-side-navigation-item-icon {
    font-size: 15px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-side-navigation-item .agtech-side-navigation-item-icon {
    font-size: 17px;
  }
}
.agtech-side-navigation-item.active {
  background-color: #3278f5 !important;
}
.agtech-side-navigation-sub-menu-body {
  transition: height 400ms;
  overflow: hidden;
  flex-direction: column;
  display: flex;
}
.agtech-side-navigation-sub-menu-body .agtech-side-navigation-item {
  background-color: #323c46;
  border-bottom: 1px solid #515762;
}
.agtech-side-navigation-sub-menu-body .agtech-side-navigation-item:first-child {
  border-top: 1px solid #515762;
}
.agtech-side-navigation-sub-menu-body .agtech-side-navigation-item:hover {
  background-color: #366dc7;
}
.agtech-app {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
}
.agtech-app .agtech-app-side-navigation {
  height: 100%;
  border-right: 1px solid #88939c;
  background-color: #1c1c1c;
  color: white;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu {
  height: 100%;
  flex-direction: column;
  position: relative;
  display: flex;
  width: 280px;
  transition: width 300ms ease;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu.collapsed {
  width: 72px;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu.collapsed .agtech-side-navigation-collapse-icon {
  transform: rotate(180deg);
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu.collapsed .agtech-side-navigation-sub-menu.open .agtech-side-navigation-sub-menu-header {
  background-color: #323c46;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-button {
  flex-direction: row;
  align-items: center;
  height: 56px;
  min-height: 56px;
  max-height: 56px;
  display: flex;
  text-decoration: none !important;
  font-size: 15px;
  padding-left: 28px;
  padding-right: 28px;
  cursor: pointer;
  color: white;
  padding-right: 24px;
  padding-left: calc((4px * 6) - 2px);
  justify-content: flex-start;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-button {
    height: 56px;
    min-height: 56px;
    max-height: 56px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-button {
    height: 56px;
    min-height: 56px;
    max-height: 56px;
  }
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-button {
    font-size: 14px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-button {
    font-size: 16px;
  }
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-button .agtech-side-navigation-item-icon {
  margin-right: 24px;
  font-size: 16px;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-button .agtech-side-navigation-item-icon {
    font-size: 15px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-button .agtech-side-navigation-item-icon {
    font-size: 17px;
  }
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-button.active {
  background-color: #3278f5 !important;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-button:hover {
  color: #3278f5;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-button:hover .agtech-button-icon {
  color: #3278f5;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-button .agtech-button-icon {
  min-width: 24px;
  max-width: 24px;
  margin-right: 24px;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  display: flex;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-button .agtech-button-icon {
    font-size: 17px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-button .agtech-button-icon {
    font-size: 19px;
  }
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-side-navigation-collapse-icon {
  position: absolute;
  bottom: 16px;
  right: 28px;
  font-size: 22px;
  min-width: 24px;
  max-width: 24px;
  color: white;
  cursor: pointer;
  z-index: 10;
  transition: transform 400ms;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-logo {
  flex-direction: row;
  height: 56px;
  min-height: 56px;
  max-height: 56px;
  display: flex;
  text-decoration: none !important;
  height: 55px;
  min-height: 55px;
  max-height: 55px;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  background-color: #1c1c1c;
  color: white;
  font-family: Orbitron;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-logo {
    height: 56px;
    min-height: 56px;
    max-height: 56px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-logo {
    height: 56px;
    min-height: 56px;
    max-height: 56px;
  }
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-logo {
    height: 55px;
    min-height: 55px;
    max-height: 55px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-logo {
    height: 55px;
    min-height: 55px;
    max-height: 55px;
  }
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-logo {
    font-size: 20px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-logo {
    font-size: 24px;
  }
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-side-navigation-sub-menu {
  flex-direction: column;
  display: flex;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-side-navigation-sub-menu .agtech-side-navigation-sub-menu-header {
  align-items: center;
  height: 56px;
  min-height: 56px;
  max-height: 56px;
  text-decoration: none !important;
  font-size: 15px;
  padding-left: 28px;
  padding-right: 28px;
  cursor: pointer;
  color: white;
  flex-direction: row;
  display: flex;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-side-navigation-sub-menu .agtech-side-navigation-sub-menu-header {
    height: 56px;
    min-height: 56px;
    max-height: 56px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-side-navigation-sub-menu .agtech-side-navigation-sub-menu-header {
    height: 56px;
    min-height: 56px;
    max-height: 56px;
  }
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-side-navigation-sub-menu .agtech-side-navigation-sub-menu-header {
    font-size: 14px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-side-navigation-sub-menu .agtech-side-navigation-sub-menu-header {
    font-size: 16px;
  }
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-side-navigation-sub-menu .agtech-side-navigation-sub-menu-header .agtech-side-navigation-item-icon {
  margin-right: 24px;
  font-size: 16px;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-side-navigation-sub-menu .agtech-side-navigation-sub-menu-header .agtech-side-navigation-item-icon {
    font-size: 15px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-side-navigation-sub-menu .agtech-side-navigation-sub-menu-header .agtech-side-navigation-item-icon {
    font-size: 17px;
  }
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-side-navigation-sub-menu .agtech-side-navigation-sub-menu-header.active {
  background-color: #3278f5 !important;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-side-navigation-sub-menu .agtech-side-navigation-sub-menu-header .agtech-side-navigation-sub-menu-header-icon {
  margin-left: auto;
  font-size: 13px;
  transition: transform 400ms;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-side-navigation-sub-menu .agtech-side-navigation-sub-menu-header .agtech-side-navigation-sub-menu-header-icon {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-side-navigation-sub-menu .agtech-side-navigation-sub-menu-header .agtech-side-navigation-sub-menu-header-icon {
    font-size: 14px;
  }
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-side-navigation-sub-menu.active .agtech-side-navigation-sub-menu-header {
  background-color: #3278f5 !important;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-side-navigation-sub-menu.open .agtech-side-navigation-sub-menu-header .agtech-side-navigation-sub-menu-header-icon {
  transform: rotate(90deg);
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-side-navigation-section {
  background-color: #dce1eb;
  border-bottom: 1px solid #c0c2c5;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-logo {
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #1c1c1c;
  height: 55px;
  margin-right: -1px;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-logo {
    height: 51px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-logo {
    height: 59px;
  }
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-logo span {
  font-size: 22px;
  text-align: center;
  color: white;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Orbitron;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-logo span {
    font-size: 20px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-logo span {
    font-size: 24px;
  }
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-logo .agtech-icon {
  color: white;
  font-size: 22px;
  padding-left: 2px;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface {
  flex-direction: column;
  flex: 1;
  display: flex;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-side-navigation-user-details {
  flex-direction: column;
  justify-content: center;
  margin-bottom: 4px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-side-navigation-user-details .agtech-side-navigation-user-details-user-icon {
  justify-content: center;
  align-items: center;
  border: 1px solid #c0c2c5;
  border-radius: 4px;
  padding: 16px;
  background-color: #e9ebef;
  display: flex;
  font-size: 28px;
  height: 60px;
  width: 60px;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-side-navigation-user-details .agtech-side-navigation-user-information-name {
  color: #0f172a;
  font-weight: 500;
  font-size: 18px;
  margin-top: 12px;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-side-navigation-user-details .agtech-side-navigation-user-information-name {
    font-size: 17px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-side-navigation-user-details .agtech-side-navigation-user-information-name {
    font-size: 19px;
  }
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-side-navigation-user-details .agtech-side-navigation-user-information-details {
  color: #64748B;
  margin-top: 4px;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section {
  overflow: hidden;
  max-height: 50px;
  transition: max-height 0.5s;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section.open {
  max-height: 600px;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section.open .agtech-app-side-navigation-section-header {
  color: #0f172a;
  font-weight: 500;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section.open .agtech-app-side-navigation-section-header .fa-chevron-up {
  transform: rotate(180deg);
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-header {
  flex-direction: column;
  justify-content: center;
  color: #0f172a;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  height: 50px;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-header {
    font-size: 14px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-header {
    font-size: 16px;
  }
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-header .fas {
  font-size: 16px;
  text-align: center;
  margin-right: 12px;
  width: 20px;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-header .fas {
    font-size: 15px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-header .fas {
    font-size: 17px;
  }
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-header .fas.fa-chevron-up {
  margin-left: auto;
  font-size: 14px;
  transition: transform 0.3s;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-header .fas.fa-chevron-up {
    font-size: 13px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-header .fas.fa-chevron-up {
    font-size: 14px;
  }
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body {
  flex-direction: column;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body a {
  color: #0f172a;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: 4px;
  margin-top: 2px;
  margin-bottom: 2px;
  text-decoration: none !important;
  border-left: 3px solid transparent;
  padding-left: 57px;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body a {
    font-size: 13px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body a {
    font-size: 14px;
  }
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body a.active {
  border-left: 3px solid #3278f5;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section {
  overflow: hidden;
  max-height: 50px;
  transition: max-height 0.5s;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section.open {
  max-height: 400px;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section.open .agtech-app-side-navigation-sub-section-header {
  color: #0f172a;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section.open .agtech-app-side-navigation-sub-section-header:hover {
  color: #0f172a;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section.open .agtech-app-side-navigation-sub-section-header .fa-chevron-up {
  transform: rotate(180deg);
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section .agtech-app-side-navigation-sub-section-header {
  font-weight: 500;
  font-size: 14px;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  display: flex;
  color: #0f172a;
  height: 50px;
  padding: 0px 10px;
  padding-left: 30px;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section .agtech-app-side-navigation-sub-section-header {
    font-size: 13px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section .agtech-app-side-navigation-sub-section-header {
    font-size: 14px;
  }
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section .agtech-app-side-navigation-sub-section-header:hover {
  color: #0f172a;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section .agtech-app-side-navigation-sub-section-header .fas {
  text-align: center;
  font-size: 18px;
  margin-right: 12px;
  width: 20px;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section .agtech-app-side-navigation-sub-section-header .fas {
    font-size: 17px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section .agtech-app-side-navigation-sub-section-header .fas {
    font-size: 19px;
  }
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section .agtech-app-side-navigation-sub-section-header .fas.fa-chevron-up {
  margin-left: auto;
  font-size: 13px;
  transition: transform 0.3s;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section .agtech-app-side-navigation-sub-section-header .fas.fa-chevron-up {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section .agtech-app-side-navigation-sub-section-header .fas.fa-chevron-up {
    font-size: 14px;
  }
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section .agtech-app-side-navigation-sub-section-body {
  flex-direction: column;
  display: flex;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section .agtech-app-side-navigation-sub-section-body a {
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 2px;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none !important;
  border-left: 3px solid transparent;
  color: #0f172a;
  padding-left: 68px;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section .agtech-app-side-navigation-sub-section-body a {
    font-size: 13px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section .agtech-app-side-navigation-sub-section-body a {
    font-size: 14px;
  }
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section .agtech-app-side-navigation-sub-section-body a:hover {
  color: #0f172a;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-app-side-navigation-section .agtech-app-side-navigation-section-body .agtech-app-side-navigation-sub-section .agtech-app-side-navigation-sub-section-body a.active {
  color: #0f172a;
  border-left: 3px solid #3278f5;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-side-navigation-link {
  height: 50px;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-side-navigation-link a {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
  color: #0f172a;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none !important;
  border-left: 3px solid transparent;
  display: flex;
  padding: 0px 17px;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-side-navigation-link a {
    font-size: 14px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-side-navigation-link a {
    font-size: 16px;
  }
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-side-navigation-link a:hover {
  color: #0f172a;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-side-navigation-link a.active {
  color: #0f172a;
  border-left: 3px solid #3278f5;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-side-navigation-link button {
  height: 100%;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  background-color: transparent;
  border: none;
  border-left: 3px solid transparent;
  padding: 0px;
  justify-content: flex-start;
  color: white;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-side-navigation-link button {
    font-size: 14px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-side-navigation-link button {
    font-size: 16px;
  }
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-side-navigation-link button:hover {
  color: #3278f5;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-side-navigation-link:hover {
  color: #0f172a;
}
.agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-side-navigation-link .fas {
  font-size: 18px;
  text-align: center;
  margin-right: 16px;
  width: 32px;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-side-navigation-link .fas {
    font-size: 17px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-side-navigation .agtech-app-side-navigation-menu .agtech-app-side-navigation-surface .agtech-side-navigation-link .fas {
    font-size: 19px;
  }
}
.agtech-app .agtech-app-surface {
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  display: flex;
}
.agtech-app .agtech-app-surface .agtech-app-header {
  height: 100%;
  overflow: hidden;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #c0c2c5;
  display: flex;
  height: 56px;
  background-color: white;
  color: #1c1c1c;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-surface .agtech-app-header {
    height: 52px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-surface .agtech-app-header {
    height: 60px;
  }
}
.agtech-app .agtech-app-surface .agtech-app-header .agtech-app-header-content {
  height: 100%;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  display: flex;
}
.agtech-app .agtech-app-surface .agtech-app-header .agtech-app-header-content .agtech-button:hover {
  background-color: transparent;
  color: #0f172a;
}
.agtech-app .agtech-app-surface .agtech-app-header .agtech-app-header-content .agtech-user-details-section {
  flex-direction: row;
  align-items: center;
  display: flex;
}
.agtech-app .agtech-app-surface .agtech-app-header .agtech-app-header-content .agtech-user-details-section .agtech-icon-badge {
  height: 32px;
  width: 32px;
  margin-right: 8px;
}
.agtech-app .agtech-app-surface .agtech-app-header .agtech-app-header-content .agtech-user-details-section .agtech-header-title {
  color: white;
  font-size: 13px;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-surface .agtech-app-header .agtech-app-header-content .agtech-user-details-section .agtech-header-title {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-surface .agtech-app-header .agtech-app-header-content .agtech-user-details-section .agtech-header-title {
    font-size: 14px;
  }
}
.agtech-app .agtech-app-surface .agtech-app-header .agtech-app-header-content .agtech-user-details-section .agtech-header-details {
  margin-top: 2px;
  font-size: 12px;
}
@media screen and (max-height: 600px) {
  .agtech-app .agtech-app-surface .agtech-app-header .agtech-app-header-content .agtech-user-details-section .agtech-header-details {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-app .agtech-app-surface .agtech-app-header .agtech-app-header-content .agtech-user-details-section .agtech-header-details {
    font-size: 13px;
  }
}
.agtech-app .agtech-app-surface .agtech-app-body-surface {
  width: 100%;
  flex: 1;
  overflow: hidden;
  background-color: #e9ebef;
  display: flex;
}
.agtech-app .agtech-app-surface .agtech-app-body-surface .agtech-app-body {
  height: 100%;
  width: 100%;
  overflow: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
}
.agtech-badge {
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #c0c2c5;
  background-color: white;
  display: flex;
}
.agtech-badge.agtech-icon-badge {
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 16px;
  height: 40px;
  width: 40px;
  background-color: #e8e9eb;
}
@media screen and (max-height: 600px) {
  .agtech-badge.agtech-icon-badge {
    font-size: 15px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-badge.agtech-icon-badge {
    font-size: 17px;
  }
}
.agtech-badge.agtech-metric-badge .agtech-metric-badge-metric-title {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid #c0c2c5;
  font-size: 13px;
  color: #0f172a;
  padding-right: 8px;
  display: flex;
}
@media screen and (max-height: 600px) {
  .agtech-badge.agtech-metric-badge .agtech-metric-badge-metric-title {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-badge.agtech-metric-badge .agtech-metric-badge-metric-title {
    font-size: 14px;
  }
}
.agtech-badge.agtech-metric-badge .agtech-metric-badge-metric-value {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding-left: 8px;
  font-size: 13px;
  color: #0f172a;
  display: flex;
}
@media screen and (max-height: 600px) {
  .agtech-badge.agtech-metric-badge .agtech-metric-badge-metric-value {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-badge.agtech-metric-badge .agtech-metric-badge-metric-value {
    font-size: 14px;
  }
}
.agtech-badge.agtech-badge-gray {
  background-color: #f6f6f6;
}
.agtech-badge.agtech-badge-blue {
  background-color: rgba(50, 120, 245, 0.2);
  border: 1px solid rgba(50, 120, 245, 0.3);
}
.agtech-badge.agtech-badge-red {
  background-color: rgba(255, 0, 0, 0.2);
  border: 1px solid rgba(255, 0, 0, 0.3);
}
.agtech-badge.agtech-badge-green {
  background-color: rgba(17, 163, 17, 0.2);
  border: 1px solid rgba(17, 163, 17, 0.7);
}
.agtech-badge.agtech-badge-yellow {
  background-color: rgba(255, 255, 0, 0.3);
  border: 1px solid rgba(218, 165, 32, 0.6);
}
.agtech-badge.agtech-badge-orange {
  background-color: rgba(255, 165, 0, 0.2);
  border: 1px solid rgba(255, 165, 0, 0.3);
}
.agtech-badge.agtech-badge-cyan {
  background-color: rgba(0, 161, 255, 0.1);
  border: 1px solid rgba(0, 140, 240, 0.5);
}
.agtech-icon-badge {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 15px;
  display: flex;
  height: 40px;
  width: 40px;
  background-color: #e9ebef;
  color: #373c41;
  border: 1px solid #dadada;
}
@media screen and (max-height: 600px) {
  .agtech-icon-badge {
    font-size: 14px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-icon-badge {
    font-size: 16px;
  }
}
.agtech-button {
  justify-content: center;
  align-items: center;
  background-color: white;
  color: #0f172a;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  border-radius: 2px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  text-decoration: none !important;
  border: 1px solid rgba(50, 120, 245, 0.8);
  box-shadow: none;
  outline: none;
  min-height: 32px;
  max-height: 100px;
  line-height: inherit;
  white-space: nowrap;
}
@media screen and (max-height: 600px) {
  .agtech-button {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-button {
    font-size: 14px;
  }
}
.agtech-button:hover {
  background-color: #e5f7ff;
}
.agtech-button:focus {
  background-color: #e5f7ff;
}
.agtech-button:focus {
  display: flex;
  outline: none;
}
.agtech-button:disabled,
.agtech-button[disabled] {
  color: #64748B !important;
  background-color: #f3f8ff !important;
  border-color: #64748B !important;
}
.agtech-button.agtech-button-green {
  background-color: #11a311;
  border: 1px solid #11a311;
}
.agtech-button.agtech-button-yellow {
  background-color: #808036;
  border: 1px solid #808036;
}
.agtech-button.agtech-button-red {
  border: 1px solid rgba(172, 34, 34, 0.5);
}
.agtech-button.agtech-button-red:hover {
  background-color: rgba(255, 0, 0, 0.05);
}
.agtech-button.agtech-button-red:focus {
  background-color: rgba(255, 0, 0, 0.05);
}
.agtech-button.highlight {
  background-color: #3278f5;
  color: white;
}
.agtech-button.highlight:hover {
  background-color: #366dc7 !important;
}
.agtech-button.highlight:focus {
  background-color: #366dc7;
}
.agtech-button.thin {
  min-height: 24px;
  height: 24px;
}
.agtech-button.link {
  padding: 0px;
  font-weight: 500;
  color: #3278f5;
  background-color: transparent;
  border: none;
}
.agtech-button.link:hover {
  color: #366dc7;
}
.agtech-button.link:hover i {
  color: #366dc7;
}
.agtech-button.text-only {
  background-color: transparent;
  border: 1px solid transparent;
}
.agtech-button.text-only:hover {
  border-color: #3278f5;
}
.agtech-button.icon-only .agtech-button-icon {
  margin: 0px;
}
.agtech-button.agtech-button-outline {
  border-color: #3278f5;
  background-color: white;
  color: #0f172a;
}
.agtech-button.agtech-button-outline:hover {
  background-color: #eff7fb;
}
.agtech-button.agtech-button-outline-subtle {
  background-color: transparent;
  border: 1px solid #425f77;
}
.agtech-button.agtech-button-outline-subtle:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.agtech-button.agtech-button-white-outline {
  background-color: transparent;
  border: 1px solid white;
}
.agtech-button.agtech-button-white-outline:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.agtech-button.block {
  flex-direction: column;
  font-size: 15px;
  display: flex;
  align-items: flex-start;
  min-width: 50%;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (max-height: 600px) {
  .agtech-button.block {
    font-size: 14px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-button.block {
    font-size: 16px;
  }
}
.agtech-button.block .agtech-button-icon {
  font-size: 22px;
  margin-bottom: 24px;
}
@media screen and (max-height: 600px) {
  .agtech-button.block .agtech-button-icon {
    font-size: 20px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-button.block .agtech-button-icon {
    font-size: 24px;
  }
}
.agtech-button-icon {
  margin-right: 12px;
}
.agtech-button-icon.icon-right {
  margin-right: 0px;
  margin-left: 12px;
}
.clickable-icon {
  cursor: pointer;
}
.clickable-icon:hover {
  color: #3278f5;
}
.agtech-checkbox {
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 0px;
  display: flex;
  /* On mouse-over, add a grey background color */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* When the checkbox is checked, add a blue background */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}
.agtech-checkbox input {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}
.agtech-checkbox .checkmark {
  position: absolute;
  border-radius: 2px;
  cursor: pointer;
  border: 1px solid #c0c2c5;
  background-color: transparent;
  height: 16px;
  width: 16px;
}
.agtech-checkbox:hover input ~ .checkmark {
  background-color: #eff7fb;
}
.agtech-checkbox .checkmark:after {
  position: absolute;
  display: none;
  content: "";
}
.agtech-checkbox input:checked ~ .checkmark {
  background-color: #3278f5;
}
.agtech-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.agtech-checkbox .checkmark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.agtech-checkbox-label {
  font-size: 13px;
  color: #64748B;
  flex-direction: column;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 4px;
  padding-right: 4px;
  margin-top: 2px;
  margin-bottom: 2px;
  display: flex;
}
@media screen and (max-height: 600px) {
  .agtech-checkbox-label {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-checkbox-label {
    font-size: 14px;
  }
}
.agtech-checkbox-label i {
  margin-right: 8px;
  font-size: 12px;
  color: #64748B;
}
@media screen and (max-height: 600px) {
  .agtech-checkbox-label i {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-checkbox-label i {
    font-size: 13px;
  }
}
.agtech-checkbox-label:hover {
  color: #0f172a;
}
.agtech-checkbox-label.checked {
  background-color: #3278f5;
  border-radius: 4px;
  color: white;
}
.agtech-checkbox-label.checked i {
  color: white;
}
.agtech-card-header {
  border-bottom: 1px solid #c0c2c5;
  width: 100%;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.agtech-card-header .agtech-action-button {
  margin-bottom: auto;
}
.agtech-card-body {
  flex: 1;
  border-radius: inherit;
  overflow: auto;
  -ms-overflow-style: none;
  /* Internet Explorer and Edge */
  /* Hide the scrollbar for Chrome, Safari and Opera */
  padding: 12px;
}
.agtech-card-body::-webkit-scrollbar {
  display: none;
}
.agtech-card-body .agtech-card-text-content {
  font-size: 13px;
  color: #64748B;
  height: 100%;
  width: 100%;
  padding: 12px;
  line-height: 18px;
}
@media screen and (max-height: 600px) {
  .agtech-card-body .agtech-card-text-content {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-card-body .agtech-card-text-content {
    font-size: 14px;
  }
}
.agtech-card-footer {
  flex-direction: row;
  align-items: center;
  padding: 12px;
  display: flex;
}
.agtech-card-surface-body > .agtech-card-body {
  background-color: #f6f6f6;
  padding: 12px;
}
.agtech-card-formal {
  width: 100%;
}
.agtech-card-formal > .agtech-card-header {
  padding: 12px;
}
.agtech-card-formal > .agtech-card-body {
  padding: 12px;
}
.agtech-card {
  flex-direction: column;
  background-color: white;
  border: 1px solid #c0c2c5;
  border-radius: 4px;
  display: flex;
}
.agtech-card > .agtech-loading-surface {
  display: inherit;
  flex-direction: inherit;
}
.agtech-card.agtech-mini-card {
  flex-direction: column;
  border: 1px solid #c0c2c5;
  background-color: white;
  border-radius: 4px;
  padding: 8px;
  display: flex;
}
.agtech-card.agtech-mini-card .agtech-card-header {
  padding: 5px 7px;
}
.agtech-card.agtech-mini-card .agtech-card-header .agtech-header .agtech-header-title {
  font-size: 13px;
  color: #0f172a;
}
@media screen and (max-height: 600px) {
  .agtech-card.agtech-mini-card .agtech-card-header .agtech-header .agtech-header-title {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-card.agtech-mini-card .agtech-card-header .agtech-header .agtech-header-title {
    font-size: 14px;
  }
}
.agtech-card.agtech-mini-card .agtech-header-content {
  flex-direction: column;
  justify-content: center;
  padding-left: 8px;
  padding-right: 2px;
  display: flex;
}
.agtech-card.agtech-simple-card {
  flex-direction: column;
  height: 100%;
  width: 100%;
  border: 1px solid #c0c2c5;
  background-color: white;
  border-radius: 2px;
  display: flex;
}
.agtech-card.agtech-simple-card .agtech-simple-card-header {
  flex-direction: column;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 1px solid #c0c2c5;
  color: #0f172a;
  height: 32px;
  background-color: #f3f8ff;
  padding: 0px 6px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
@media screen and (max-height: 600px) {
  .agtech-card.agtech-simple-card .agtech-simple-card-header {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-card.agtech-simple-card .agtech-simple-card-header {
    font-size: 14px;
  }
}
.agtech-card.agtech-simple-card .agtech-card-body {
  flex: 1;
}
.agtech-card.agtech-simple-card .agtech-card-body .agtech-card-text-content {
  font-size: 15px;
  font-weight: 500;
  padding: 6px;
}
@media screen and (max-height: 600px) {
  .agtech-card.agtech-simple-card .agtech-card-body .agtech-card-text-content {
    font-size: 14px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-card.agtech-simple-card .agtech-card-body .agtech-card-text-content {
    font-size: 16px;
  }
}
.agtech-card-collection {
  flex-direction: column;
  height: 100%;
  width: 100%;
  display: flex;
}
.agtech-card-collection .agtech-card-collection-body {
  overflow: auto;
  display: grid;
  grid-gap: 16px;
}
@keyframes dialogFadeIn {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.agtech-dialog {
  flex-direction: column;
  height: 100%;
  background-color: white;
  transition: width 200ms linear;
  transition: width 1s;
  position: relative;
  display: flex;
  animation-duration: 500ms;
  animation-name: dialogFadeIn;
}
.agtech-dialog .agtech-dialog-header {
  align-items: center;
  border-bottom: 1px solid #c0c2c5;
  font-size: 15px;
  font-weight: 500;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #f6f7fb;
  display: flex;
}
@media screen and (max-height: 600px) {
  .agtech-dialog .agtech-dialog-header {
    font-size: 14px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-dialog .agtech-dialog-header {
    font-size: 16px;
  }
}
.agtech-dialog .agtech-dialog-body {
  flex: 1;
  position: relative;
}
.agtech-dialog .agtech-dialog-body .agtech-dialog-body-surface {
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
}
.agtech-dialog .agtech-dialog-footer {
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}
.agtech-dialog .agtech-dialog-footer .agtech-button {
  padding: 6px 24px;
}
.agtech-dialog .agtech-dialog-footer .agtech-button:last-of-type {
  margin-left: auto;
}
.agtech-dialog.agtech-view-dialog {
  overflow: hidden;
  border: none;
  width: 400px;
  transition: width 1s;
}
.agtech-dialog.agtech-view-dialog .agtech-dialog-body {
  background-color: #e9ebef;
}
.agtech-dialog.agtech-view-dialog .agtech-dialog-footer {
  height: 60px;
  border-top: 1px solid #c0c2c5;
}
.agtech-input {
  width: 100%;
  border: 1px solid #c0c2c5;
  background-color: white;
  font-size: 14px;
  padding-left: 12px;
  outline: none !important;
  display: flex;
  height: 36px;
  padding-bottom: 0px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
@media screen and (max-height: 600px) {
  .agtech-input {
    font-size: 13px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-input {
    font-size: 14px;
  }
}
.agtech-input[type="date"] {
  padding-right: 8px;
}
.agtech-input:read-only {
  background-color: #f8fbfb;
}
.agtech-input:focus {
  outline: none !important;
  border: 1px solid #878c97;
}
.agtech-multi-step-dialog-step-badges {
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}
.agtech-multi-step-dialog-step-badges .agtech-multi-step-dialog-step {
  flex-direction: row;
  align-items: center;
  display: flex;
}
.agtech-multi-step-dialog-step-badges .agtech-multi-step-dialog-step .agtech-multi-step-dialog-step-badge {
  border: 1px solid #c0c2c5;
  border-radius: 4px;
  font-weight: 500;
  color: #64748B;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 16px;
  line-height: 20px;
}
.agtech-multi-step-dialog-step-badges .agtech-multi-step-dialog-step .agtech-multi-step-dialog-step-badge.active {
  background-color: #3278f5;
  color: white;
}
.agtech-multi-step-dialog-step-badges .agtech-multi-step-dialog-step .agtech-multi-step-dialog-step-divider {
  height: 1px;
  width: 20px;
  border: 1px solid #c0c2c5;
  margin-left: 8px;
  margin-right: 8px;
}
.agtech-form {
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
}
.agtech-form .agtech-form-button {
  height: 36px;
}
.agtech-form .agtech-form-tabs {
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  display: flex;
}
.agtech-form .agtech-form-tabs .agtech-form-tabs-header {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  display: flex;
  min-height: 42px;
}
.agtech-form .agtech-form-tabs .agtech-form-tabs-header .agtech-form-tab-header-tab {
  justify-content: center;
  align-items: center;
  color: #0f172a;
  margin-right: 14px;
  cursor: pointer;
  min-height: 40px;
  min-width: 100px;
  border-bottom: 3px solid #e6e6e6;
}
.agtech-form .agtech-form-tabs .agtech-form-tabs-header .agtech-form-tab-header-tab:last-child {
  margin-right: 0px;
}
.agtech-form .agtech-form-tabs .agtech-form-tabs-header .agtech-form-tab-header-tab .agtech-tab-text {
  font-weight: 400;
}
.agtech-form .agtech-form-tabs .agtech-form-tabs-header .agtech-form-tab-header-tab:hover {
  border-bottom: 3px solid #c0c2c5;
}
.agtech-form .agtech-form-tabs .agtech-form-tabs-header .agtech-form-tab-header-tab.active {
  font-weight: 500;
  border-bottom: 3px solid #3278f5;
}
.agtech-form .agtech-form-tabs .agtech-form-tabs-body {
  flex: 1;
  overflow: auto;
}
.agtech-form .agtech-form-tabs .agtech-form-tabs-body .agtech-form-tab-body-tab {
  display: none;
}
.agtech-form .agtech-form-tabs .agtech-form-tabs-body .agtech-form-tab-body-tab.active {
  display: block;
}
.agtech-form-row {
  padding-top: 12px;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
.agtech-form-control {
  flex-direction: column;
  flex: 1;
  padding-right: 20px;
  max-width: 50%;
}
.agtech-form-control:last-child {
  padding-right: 0px;
}
.agtech-form-control.invalid {
  outline: none !important;
}
.agtech-form-control.invalid input,
.agtech-form-control.invalid textarea {
  border: 1px solid goldenrod !important;
}
.agtech-form-control.highlighted input {
  background-color: #eff7fb !important;
}
.agtech-form-control .agtech-form-control-caption {
  font-size: 12px;
  font-weight: 500;
  color: #64748B;
  margin-left: 1px;
  margin-bottom: 5px;
}
@media screen and (max-height: 600px) {
  .agtech-form-control .agtech-form-control-caption {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-form-control .agtech-form-control-caption {
    font-size: 13px;
  }
}
.agtech-form-control input {
  width: 100%;
  border: 1px solid #c0c2c5;
  background-color: white;
  font-size: 14px;
  padding-left: 12px;
  outline: none !important;
  display: flex;
  height: 36px;
  padding-bottom: 0px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
@media screen and (max-height: 600px) {
  .agtech-form-control input {
    font-size: 13px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-form-control input {
    font-size: 14px;
  }
}
.agtech-form-control input[type="date"] {
  padding-right: 8px;
}
.agtech-form-control input:read-only {
  background-color: #f8fbfb;
}
.agtech-form-control input:focus {
  outline: none !important;
  border: 1px solid #878c97;
}
.agtech-form-control textarea {
  border: 1px solid #c0c2c5;
  background-color: white;
  font-size: 14px;
  outline: none !important;
  display: flex;
  height: 36px;
  padding-bottom: 0px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  height: auto;
}
@media screen and (max-height: 600px) {
  .agtech-form-control textarea {
    font-size: 13px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-form-control textarea {
    font-size: 14px;
  }
}
.agtech-form-control textarea[type="date"] {
  padding-right: 8px;
}
.agtech-form-control textarea:read-only {
  background-color: #f8fbfb;
}
.agtech-form-control textarea:focus {
  outline: none !important;
  border: 1px solid #878c97;
}
.agtech-form-control .agtech-form-control-unit {
  justify-content: center;
  align-items: center;
  border: 1px solid #c0c2c5;
  padding-left: 12px;
  padding-right: 12px;
  height: 100%;
  font-weight: 500;
  background-color: #e5f7ff;
  display: flex;
  height: 36px;
  border-right: none;
  min-width: 36px;
}
.agtech-form-control .agtech-form-control-unit.agtech-form-control-unit-right {
  border: 1px solid #c0c2c5;
  border-left: none;
}
.agtech-form-control .agtech-toggle-surface {
  flex-direction: row;
  align-items: center;
  display: flex;
  height: 36px;
}
.agtech-form-control .agtech-toggle-surface .agtech-toggle-container {
  margin: 0px;
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}
.agtech-form-control .agtech-toggle-surface .agtech-toggle-container:hover .agtech-toggle {
  background-color: #e9ebef;
  border-color: #878c97;
}
.agtech-form-control .agtech-toggle-surface .agtech-toggle-container input {
  opacity: 0 !important;
}
.agtech-form-control .agtech-toggle-surface .agtech-toggle-container input:focus ~ .agtech-toggle {
  background-color: #e9ebef;
  border-color: #878c97;
}
.agtech-form-control .agtech-toggle-surface .agtech-toggle-container input:checked ~ .agtech-toggle {
  background-color: #3278f5;
}
.agtech-form-control .agtech-toggle-surface .agtech-toggle-container input:checked:focus ~ .agtech-toggle {
  background-color: #366dc7;
}
.agtech-form-control .agtech-toggle-surface .agtech-toggle-container input:checked ~ .agtech-toggle:before {
  transform: translateX(23px);
}
.agtech-form-control .agtech-toggle-surface .agtech-toggle-container .agtech-toggle {
  cursor: pointer;
  border: 1px solid #c0c2c5;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #f6f7fb;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 24px;
}
.agtech-form-control .agtech-toggle-surface .agtech-toggle-container .agtech-toggle:before {
  position: absolute;
  background-color: white;
  border-radius: 50%;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.agtech-editable-text {
  outline: none !important;
}
.grid-selection-control {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  border: 1px solid #c0c2c5;
  border-color: transparent;
}
.grid-selection-control:focus-within {
  border: 1px solid #c0c2c5;
  border-color: #3278f5;
  background-color: white;
}
.grid-selection-control .grid-selection-control-value {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  line-height: 36px;
  width: calc(100% - 4px * 6);
}
.grid-selection-control .grid-selection-control-value.active {
  color: rgba(115, 115, 115, 0.87) !important;
}
.grid-selection-control .grid-selection-control-value.filtered {
  visibility: hidden;
}
.grid-selection-control .grid-selection-control-value.required {
  width: calc(100% - 4px * 3);
}
.grid-selection-control .grid-selection-control-search {
  height: 100%;
  width: 100%;
  position: absolute;
  border: none;
  outline: none !important;
  z-index: 2;
  background-color: transparent;
  padding-left: 12px;
  padding-right: 12px;
}
.grid-selection-control .grid-selection-control-icon {
  position: absolute;
  cursor: pointer;
  font-size: 10px;
  top: 13px;
  right: 16px;
}
.selection-form-control {
  background-color: white;
  position: relative;
  display: flex;
  height: 36px;
}
.selection-form-control .selection-form-control-value {
  position: relative;
  color: #0f172a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  left: 15px;
  line-height: 36px;
  width: calc(100% - 4px * 6);
}
.selection-form-control .selection-form-control-value.active {
  color: rgba(115, 115, 115, 0.87) !important;
}
.selection-form-control .selection-form-control-value.filtered {
  visibility: hidden;
}
.selection-form-control .selection-form-control-value.required {
  width: calc(100% - 4px * 3);
}
.selection-form-control .selection-form-control-search {
  position: absolute;
  background-color: transparent;
  left: 1px;
}
.selection-form-control .selection-form-control-icon {
  position: absolute;
  cursor: pointer;
  font-size: 10px;
  top: 13px;
  right: 16px;
}
.bi,
.fa-bi {
  font-size: 14px;
}
@media screen and (max-height: 600px) {
  .bi,
  .fa-bi {
    font-size: 13px;
  }
}
@media screen and (min-height: 1000px) {
  .bi,
  .fa-bi {
    font-size: 14px;
  }
}
.agtech-dropdown {
  position: relative;
  background-color: white;
  height: 36px;
}
.agtech-dropdown .agtech-dropdown-search {
  position: absolute;
  background-color: transparent;
  z-index: 2;
  left: 1px;
}
.agtech-dropdown .agtech-dropdown-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  left: 15px;
  line-height: 36px;
  width: calc(100% - 4px * 6);
}
.agtech-dropdown .agtech-dropdown-text.active {
  color: rgba(115, 115, 115, 0.87) !important;
}
.agtech-dropdown .agtech-dropdown-text.filtered {
  visibility: hidden;
}
.agtech-dropdown .agtech-dropdown-text.required {
  width: calc(100% - 4px * 3);
}
.agtech-dropdown .agtech-dropdown-icon {
  position: absolute;
  cursor: pointer;
  font-size: 10px;
  top: 13px;
  right: 16px;
  z-index: 5;
}
.agtech-dropdown .agtech-dropdown-clear-icon {
  color: #64748B;
  position: absolute;
  cursor: pointer;
  font-size: 10px;
  top: 13px;
  right: 32px;
  z-index: 5;
}
.agtech-dropdown .agtech-dropdown-clear-icon:hover {
  color: #0f172a;
}
.agtech-dropdown .agtech-dropdown-item-list {
  position: absolute;
  background-color: white;
  flex-direction: column;
  flex: 1;
  border-radius: 2px;
  display: flex;
  top: 100%;
  right: 0px;
  margin-top: 8px;
  overflow-y: auto;
  height: 0px;
  min-width: 100%;
  transition: height 100ms;
  z-index: 10;
}
.agtech-dropdown .agtech-dropdown-item-list.scrollable {
  overflow-y: auto;
}
.agtech-dropdown .agtech-dropdown-item-list.visible {
  border: 1px solid #c0c2c5;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.06), 0 3px 16px rgba(0, 0, 0, 0.08), 0 4px 24px rgba(0, 0, 0, 0.1);
}
.agtech-dropdown .agtech-dropdown-item-list .agtech-dropdown-item {
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  display: flex;
  border-left: 3px solid transparent;
  border-bottom: 1px solid #e6e6e6;
  pointer-events: all;
}
.agtech-dropdown .agtech-dropdown-item-list .agtech-dropdown-item .agtech-header-title {
  white-space: nowrap;
  font-size: 13px;
}
@media screen and (max-height: 600px) {
  .agtech-dropdown .agtech-dropdown-item-list .agtech-dropdown-item .agtech-header-title {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-dropdown .agtech-dropdown-item-list .agtech-dropdown-item .agtech-header-title {
    font-size: 14px;
  }
}
.agtech-dropdown .agtech-dropdown-item-list .agtech-dropdown-item .agtech-header-details {
  white-space: nowrap;
  font-size: 13px;
}
@media screen and (max-height: 600px) {
  .agtech-dropdown .agtech-dropdown-item-list .agtech-dropdown-item .agtech-header-details {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-dropdown .agtech-dropdown-item-list .agtech-dropdown-item .agtech-header-details {
    font-size: 14px;
  }
}
.agtech-dropdown .agtech-dropdown-item-list .agtech-dropdown-item:last-child {
  border-bottom: none;
}
.agtech-dropdown .agtech-dropdown-item-list .agtech-dropdown-item.agtech-dropdown-add-item {
  color: #0f172a;
  cursor: pointer;
  background-color: #e5f7ff !important;
  pointer-events: all !important;
  height: 40px;
}
.agtech-dropdown .agtech-dropdown-item-list .agtech-dropdown-item.agtech-dropdown-add-item .agtech-icon {
  margin: 0px;
  margin-right: 8px;
  color: #0f172a;
  width: auto;
  min-width: 0px;
}
.agtech-dropdown .agtech-dropdown-item-list .agtech-dropdown-item.active {
  font-weight: 400 !important;
  background-color: rgba(252, 252, 252, 0.3);
}
.agtech-dropdown .agtech-dropdown-item-list .agtech-dropdown-item.selected {
  border-left: 3px solid #3278f5;
  font-weight: 500;
  background-color: rgba(252, 252, 252, 0.3);
}
.agtech-dropdown .agtech-dropdown-item-list .agtech-dropdown-item:hover {
  background-color: rgba(252, 252, 252, 0.3);
}
.agtech-dropdown .agtech-dropdown-item-list .agtech-dropdown-item .text {
  white-space: nowrap;
}
.agtech-grid {
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
}
.agtech-grid .agtech-grid-header {
  align-items: center;
  padding-bottom: 12px;
  display: flex;
  height: 44px;
}
.agtech-grid .agtech-grid-header .agtech-grid-title {
  align-items: center;
  font-size: 15px;
  color: #0f172a;
  font-weight: 500;
  margin-top: 4px;
  padding-left: 4px;
  display: flex;
}
@media screen and (max-height: 600px) {
  .agtech-grid .agtech-grid-header .agtech-grid-title {
    font-size: 14px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-grid .agtech-grid-header .agtech-grid-title {
    font-size: 16px;
  }
}
.agtech-grid .agtech-grid-header .agtech-grid-search-container {
  height: 100%;
  margin-left: 16px;
  display: flex;
}
.agtech-grid .agtech-grid-header .agtech-grid-search-container .agtech-grid-search-button {
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #c0c2c5;
  color: #3278f5;
  padding: 0px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-right: none;
  width: 40px;
}
.agtech-grid .agtech-grid-header .agtech-grid-search-container .agtech-grid-search-button i {
  margin: 0px;
}
.agtech-grid .agtech-grid-header .agtech-grid-search-container .agtech-grid-search-button:hover {
  background-color: #eff7fb;
}
.agtech-grid .agtech-grid-header .agtech-grid-search-container .agtech-grid-search-box {
  border: 1px solid #c0c2c5;
  font-size: 13px;
  padding-left: 8px;
  padding-right: 8px;
  outline: none !important;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  min-width: 160px;
}
@media screen and (max-height: 600px) {
  .agtech-grid .agtech-grid-header .agtech-grid-search-container .agtech-grid-search-box {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-grid .agtech-grid-header .agtech-grid-search-container .agtech-grid-search-box {
    font-size: 14px;
  }
}
.agtech-grid .agtech-grid-surface {
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  display: flex;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-header {
  border-top: 1px solid #c0c2c5;
  border-left: 1px solid #c0c2c5;
  border-right: 1px solid #c0c2c5;
  border-bottom: 1px solid #c0c2c5;
  align-items: center;
  background-color: white;
  width: 100%;
  display: flex;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-header .agtech-grid-surface-selection-column {
  height: 100%;
  border-right: 1px solid #c0c2c5;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-header .agtech-grid-surface-header-selection-cell {
  justify-content: center;
  align-items: center;
  width: 40px;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-header .agtech-grid-surface-header-actions-cell {
  justify-content: center;
  align-items: center;
  width: 30px;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-header .agtech-grid-surface-header-cell {
  flex-direction: column;
  justify-content: center;
  height: 100%;
  display: flex;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-header .agtech-grid-surface-header-cell .agtech-grid-surface-header-cell-content {
  width: 100%;
  font-size: 12px;
  color: #0f172a;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
}
@media screen and (max-height: 600px) {
  .agtech-grid .agtech-grid-surface .agtech-grid-surface-header .agtech-grid-surface-header-cell .agtech-grid-surface-header-cell-content {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-grid .agtech-grid-surface .agtech-grid-surface-header .agtech-grid-surface-header-cell .agtech-grid-surface-header-cell-content {
    font-size: 13px;
  }
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-action-column {
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  display: flex;
  width: 36px;
  min-width: 36px;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-action-column:first-of-type {
  border-left: 1px solid #e6e6e6;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-action-column button {
  margin: 0px;
  margin-left: 4px;
  margin-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 0px;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-action-column .agtech-action-button .agtech-icon {
  font-size: 13px;
  color: #64748B;
  cursor: pointer;
}
@media screen and (max-height: 600px) {
  .agtech-grid .agtech-grid-surface .agtech-grid-surface-action-column .agtech-action-button .agtech-icon {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-grid .agtech-grid-surface .agtech-grid-surface-action-column .agtech-action-button .agtech-icon {
    font-size: 14px;
  }
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-action-column .agtech-action-button .agtech-icon:hover {
  color: #3278f5;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-action-column.agtech-grid-surface-selection-column {
  border-right: 1px solid #c0c2c5;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body {
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #c0c2c5;
  border-bottom: 1px solid #c0c2c5;
  display: flex;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-inner-body {
  background-color: white;
  border: 1px solid #c0c2c5;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row {
  flex-direction: row;
  background-color: white;
  border-left: 1px solid #c0c2c5;
  display: flex;
  border-bottom: 1px solid #e6e6e6;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row.odd {
  background-color: #fcfcfc;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row.selected {
  background-color: #eff7fb !important;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row.focused {
  background-color: #eff7fb;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row.focused .agtech-grid-surface-body-row-cell.emphasized {
  background-color: #eff7fb;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row.invalid {
  background-color: #ffffe2;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row.invalid .agtech-grid-surface-body-row-cell {
  background-color: #ffffe2;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row:last-child {
  border-bottom: none;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row .agtech-badge {
  font-size: 12px;
}
@media screen and (max-height: 600px) {
  .agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row .agtech-badge {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row .agtech-badge {
    font-size: 13px;
  }
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row .agtech-grid-surface-body-row-cell {
  flex-direction: column;
  justify-content: center;
  font-size: 13px;
  overflow: hidden;
  border-right: 1px solid #c0c2c5;
  display: flex;
}
@media screen and (max-height: 600px) {
  .agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row .agtech-grid-surface-body-row-cell {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row .agtech-grid-surface-body-row-cell {
    font-size: 14px;
  }
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row .agtech-grid-surface-body-row-cell:last-child {
  border-right: none;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row .agtech-grid-surface-body-row-cell.emphasized {
  font-weight: 500;
  border-right: 1px solid #c0c2c5;
  background-color: #f6f6f6;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row .agtech-grid-surface-body-row-cell.emphasized .agtech-grid-surface-body-row-cell-content {
  color: #373c41;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row .agtech-grid-surface-body-row-cell .agtech-grid-surface-body-row-cell-content {
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-direction: column;
  justify-content: center;
  display: flex;
  line-height: 36px;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row .agtech-grid-surface-body-row-cell .agtech-grid-surface-body-row-cell-content.invalid {
  background-color: rgba(255, 255, 0, 0.25);
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row .agtech-grid-surface-body-row-cell .agtech-grid-surface-body-row-cell-content.with-link:hover {
  cursor: pointer;
  color: #3278f5;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row .agtech-grid-surface-body-row-cell .agtech-grid-surface-body-row-cell-content .agtech-form-control {
  height: 100%;
  width: 100%;
  padding: 0px;
  background-color: transparent;
  border: none;
  outline: none !important;
  max-width: 100%;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row .agtech-grid-surface-body-row-cell .agtech-grid-surface-body-row-cell-content .agtech-form-control input {
  height: 100%;
  width: 100%;
  font-size: 13px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: transparent;
  border: 1px solid #c0c2c5;
  border-color: transparent;
}
@media screen and (max-height: 600px) {
  .agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row .agtech-grid-surface-body-row-cell .agtech-grid-surface-body-row-cell-content .agtech-form-control input {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row .agtech-grid-surface-body-row-cell .agtech-grid-surface-body-row-cell-content .agtech-form-control input {
    font-size: 14px;
  }
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row .agtech-grid-surface-body-row-cell .agtech-grid-surface-body-row-cell-content .agtech-form-control input:focus {
  border: 1px solid #c0c2c5;
  border-color: #3278f5;
  background-color: white;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row .agtech-grid-surface-body-row-cell.invalid {
  background-color: rgba(255, 255, 0, 0.25);
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row .agtech-grid-surface-body-row-cell.status {
  border-left: 1px solid #c0c2c5;
  flex: inherit !important;
  padding: 0px 4px;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-body .agtech-grid-surface-body-row .agtech-grid-surface-body-row-cell.right .agtech-grid-surface-body-row-cell-content {
  justify-content: flex-end;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-footer {
  margin-top: auto;
  flex-direction: column;
  justify-content: center;
  padding-top: 8px;
  background-color: transparent;
  height: 52px;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-footer .agtech-grid-surface-footer-cell {
  flex-direction: column;
  justify-content: center;
  height: 100%;
  flex: 1;
  font-size: 13px;
  font-weight: 500;
  padding-left: 8px;
  padding-right: 8px;
  color: #60676d;
}
@media screen and (max-height: 600px) {
  .agtech-grid .agtech-grid-surface .agtech-grid-surface-footer .agtech-grid-surface-footer-cell {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-grid .agtech-grid-surface .agtech-grid-surface-footer .agtech-grid-surface-footer-cell {
    font-size: 14px;
  }
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-footer .agtech-grid-surface-footer-cell.emphasized {
  border-right: 1px solid #c0c2c5;
  margin-right: 8px;
  flex: 0.8;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-footer .agtech-grid-view-summary {
  font-size: 14px;
  margin-right: 8px;
}
@media screen and (max-height: 600px) {
  .agtech-grid .agtech-grid-surface .agtech-grid-surface-footer .agtech-grid-view-summary {
    font-size: 13px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-grid .agtech-grid-surface .agtech-grid-surface-footer .agtech-grid-view-summary {
    font-size: 14px;
  }
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-footer .agtech-grid-pagination-control .row-based-vertically-centered .agtech-button {
  justify-content: center;
  align-items: center;
  background-color: white;
  color: #3278f5;
  border: none;
  border-right: 1px solid #c0c2c5;
  border-top: 1px solid #c0c2c5;
  border-bottom: 1px solid #c0c2c5;
  border-radius: 0px;
  width: 40px;
  min-height: 36px;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-footer .agtech-grid-pagination-control .row-based-vertically-centered .agtech-button.active {
  background-color: #3278f5;
  color: white;
  background-color: #3278f5 !important;
  color: white !important;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-footer .agtech-grid-pagination-control .row-based-vertically-centered .agtech-button:hover {
  outline: none !important;
  background-color: #e5f7ff;
  border: none;
  border-right: 1px solid #c0c2c5;
  border-top: 1px solid #c0c2c5;
  border-bottom: 1px solid #c0c2c5;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-footer .agtech-grid-pagination-control .row-based-vertically-centered .agtech-button:first-child {
  border-left: 1px solid #c0c2c5;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.agtech-grid .agtech-grid-surface .agtech-grid-surface-footer .agtech-grid-pagination-control .row-based-vertically-centered .agtech-button:last-child {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.agtech-grid.grouped .agtech-grid-surface-body-row.agtech-grid-surface-body-group-header {
  background-color: #f6f7fb;
  border-top: 1px solid #c0c2c5;
  border-bottom: 1px solid #c0c2c5;
}
.agtech-grid.grouped .agtech-grid-surface-body-row.agtech-grid-surface-body-group-header:first-child {
  border-top: none;
}
.agtech-header {
  flex: 1;
}
.agtech-header.agtech-title-detail-action-header {
  flex-direction: row;
}
.agtech-header.agtech-title-detail-action-header .agtech-icon-badge {
  font-size: 15px;
  margin-right: 8px;
}
@media screen and (max-height: 600px) {
  .agtech-header.agtech-title-detail-action-header .agtech-icon-badge {
    font-size: 14px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-header.agtech-title-detail-action-header .agtech-icon-badge {
    font-size: 16px;
  }
}
.agtech-header.agtech-title-detail-action-header .agtech-title-details-container {
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  padding-right: 8px;
}
.agtech-header.agtech-title-detail-action-header .agtech-title-details-container .agtech-header-title {
  font-weight: 500;
  overflow: hidden !important;
}
.agtech-header.agtech-title-detail-action-header .agtech-title-details-container .agtech-header-details {
  color: #64748B;
  margin-top: 4px;
}
.agtech-header.agtech-title-detail-action-header .agtech-header-content {
  font-size: 13px;
  padding-top: 4px;
  padding-right: 4px;
}
@media screen and (max-height: 600px) {
  .agtech-header.agtech-title-detail-action-header .agtech-header-content {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-header.agtech-title-detail-action-header .agtech-header-content {
    font-size: 14px;
  }
}
.agtech-header.agtech-title-detail-action-header .agtech-header-content .agtech-button {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 4px;
  white-space: nowrap;
  max-height: 36px;
}
.agtech-header.agtech-title-detail-action-header .agtech-header-content .agtech-button:first-of-type {
  margin-left: 0px;
}
.agtech-header.agtech-title-detail-action-header .agtech-header-content .agtech-badge {
  min-height: 32px;
}
.agtech-simple-header {
  padding: 8px;
  font-weight: 500;
  border-bottom: 1px solid #c0c2c5;
}
.agtech-link {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.agtech-link:hover {
  color: #3278f5;
}
.agtech-list {
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  display: flex;
}
.agtech-list .agtech-list-header {
  border-bottom: 1px solid #c0c2c5;
  font-weight: 500;
  padding-bottom: 5px;
}
.agtech-list .agtech-list-header .agtech-header-title {
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: 15px;
  margin-top: 3px;
}
@media screen and (max-height: 600px) {
  .agtech-list .agtech-list-header .agtech-header-title {
    font-size: 14px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-list .agtech-list-header .agtech-header-title {
    font-size: 16px;
  }
}
.agtech-list .agtech-list-body {
  flex: 1;
  overflow: hidden;
  display: flex;
}
.agtech-list .agtech-list-body .agtech-list-row {
  padding: 16px;
  border-bottom: 1px solid #e6e6e6;
}
.agtech-list .agtech-list-body .agtech-list-row .agtech-card-collection-header .agtech-header-title {
  margin-top: 3px;
}
.agtech-list .agtech-list-body .agtech-list-row:last-child {
  border-bottom: none;
}
.agtech-list.agtech-list-compact .agtech-list-body .agtech-list-row {
  padding: 0px;
  padding-bottom: 16px;
  border-bottom: none;
}
.agtech-left-right-list {
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 2px 6px;
}
.agtech-left-right-list .agtech-left-right-list-item {
  flex-direction: column;
  justify-content: center;
  flex-direction: row;
  flex: 1;
  border-bottom: 1px solid #e6e6e6;
}
.agtech-left-right-list .agtech-left-right-list-item .agtech-left-right-list-item-title {
  font-size: 13px;
  color: #64748B;
}
@media screen and (max-height: 600px) {
  .agtech-left-right-list .agtech-left-right-list-item .agtech-left-right-list-item-title {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-left-right-list .agtech-left-right-list-item .agtech-left-right-list-item-title {
    font-size: 14px;
  }
}
.agtech-left-right-list .agtech-left-right-list-item .agtech-left-right-list-item-value {
  font-size: 13px;
  color: #0f172a;
  margin-left: auto;
}
@media screen and (max-height: 600px) {
  .agtech-left-right-list .agtech-left-right-list-item .agtech-left-right-list-item-value {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-left-right-list .agtech-left-right-list-item .agtech-left-right-list-item-value {
    font-size: 14px;
  }
}
.agtech-left-right-list .agtech-left-right-list-item:last-child {
  border-bottom: none;
}
.agtech-metric {
  border: 1px solid #c0c2c5;
  background-color: white;
  width: 100%;
  flex-direction: row;
  display: flex;
  border-radius: 2px;
  padding: 12px;
}
.agtech-metric .agtech-metric-header-value-container {
  height: 100%;
  flex-direction: column;
  display: flex;
}
.agtech-metric .agtech-metric-header-value-container .agtech-metric-header {
  font-size: 14px;
  color: #64748B;
  font-weight: 500;
  margin-top: 20px;
  line-height: 1;
}
@media screen and (max-height: 600px) {
  .agtech-metric .agtech-metric-header-value-container .agtech-metric-header {
    font-size: 13px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-metric .agtech-metric-header-value-container .agtech-metric-header {
    font-size: 14px;
  }
}
.agtech-metric .agtech-metric-header-value-container .agtech-metric-value {
  line-height: 1;
  margin-left: 1px;
  font-weight: 500;
  font-size: 20px;
}
@media screen and (max-height: 600px) {
  .agtech-metric .agtech-metric-header-value-container .agtech-metric-value {
    font-size: 18px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-metric .agtech-metric-header-value-container .agtech-metric-value {
    font-size: 22px;
  }
}
.agtech-metric .agtech-metric-content-container {
  height: 100%;
  margin-left: auto;
  align-items: center;
  display: flex;
}
.agtech-header-metric {
  height: 100%;
  border-right: 1px solid #c0c2c5;
  padding-right: 12px;
  margin-right: 12px;
}
.agtech-header-metric .agtech-header-metric-header {
  font-size: 13px;
  color: #64748B;
  margin-top: 6px;
  line-height: 1;
}
@media screen and (max-height: 600px) {
  .agtech-header-metric .agtech-header-metric-header {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-header-metric .agtech-header-metric-header {
    font-size: 14px;
  }
}
.agtech-header-metric .agtech-header-metric-value {
  line-height: 1;
  font-size: 18px;
  margin-bottom: auto;
}
.agtech-header-button {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid #c0c2c5;
  padding-right: 12px;
  margin-right: 12px;
}
.agtech-modal-surface {
  opacity: 0;
  animation: fadeIn 400ms forwards 0ms;
  flex-direction: column;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 0px 8px 0px #373c41;
  min-width: 360px;
  transition: all 1s ease;
  max-height: 95%;
  max-width: 95%;
  display: flex;
}
.agtech-modal-surface .agtech-modal-header {
  justify-content: center;
  align-items: center;
  padding: 12px;
  background-color: #324c77;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.agtech-modal-surface .agtech-modal-header .agtech-modal-close-button {
  background-color: transparent;
  color: white;
  font-size: 18px;
  border: none;
}
@media screen and (max-height: 600px) {
  .agtech-modal-surface .agtech-modal-header .agtech-modal-close-button {
    font-size: 17px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-modal-surface .agtech-modal-header .agtech-modal-close-button {
    font-size: 19px;
  }
}
.agtech-modal-surface .agtech-modal-header .agtech-header-title {
  color: white;
}
.agtech-modal-surface .agtech-modal-header .agtech-header-details {
  margin-top: 6px !important;
  color: lightgray !important;
}
.agtech-modal-surface .agtech-modal-close-button {
  border: none;
  font-size: 16px;
  color: #64748B;
  background-color: transparent;
  margin-bottom: auto;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 100;
}
@media screen and (max-height: 600px) {
  .agtech-modal-surface .agtech-modal-close-button {
    font-size: 15px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-modal-surface .agtech-modal-close-button {
    font-size: 17px;
  }
}
.agtech-modal-surface .agtech-modal-body {
  flex: 1;
  overflow: hidden;
  display: flex;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.agtech-modal-surface .agtech-modal-body .agtech-modal-header-content {
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.agtech-modal-surface .agtech-modal-body .agtech-modal-body-content {
  width: 100%;
  border-top: 1px solid #c0c2c5;
  padding-top: 8px;
  margin-top: 8px;
}
.agtech-modal-surface .agtech-modal-body .agtech-confirmation-modal-content {
  flex-direction: column;
  width: 100%;
}
.agtech-modal-surface .agtech-modal-body .agtech-confirmation-modal-content .agtech-confirmation-modal-content-body {
  padding: 8px;
}
.agtech-modal-surface .agtech-modal-body .agtech-confirmation-modal-content .agtech-modal-footer {
  padding: 12px;
}
.agtech-modal-surface .agtech-modal-body > div {
  border-radius: inherit;
}
.agtech-modal-surface .agtech-modal-footer {
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid #c0c2c5;
  padding: 8px;
}
.agtech-modal-surface.agtech-warning-modal {
  width: 400px;
  border: 2px solid goldenrod;
}
.agtech-modal-holder {
  background-color: #e9ebef;
}
.agtech-modal-holder .agtech-modal-holder-cover {
  height: 100%;
  width: 100%;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.5);
}
.agtech-modal-holder .agtech-modal-holder-cover .agtech-modal-surface {
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.agtech-modal-holder .agtech-modal-holder-cover .agtech-modal-surface .agtech-modal {
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
}
.agtech-modal-holder .agtech-modal-holder-cover .agtech-modal-surface .agtech-modal .agtech-modal-body {
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: 100%;
  padding: 12px;
}
.agtech-modal-holder .agtech-modal-holder-cover .agtech-modal-surface .agtech-modal .agtech-modal-footer {
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid #c0c2c5;
  padding: 8px;
}
.agtech-modal-holder.show {
  display: flex;
}
.agtech-search-control-container {
  flex-direction: column;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
  border: 1px solid #c0c2c5;
  background-color: white;
  min-width: 200px;
}
.agtech-search-control-container .agtech-search-control {
  font-size: 13px;
  border: none;
  outline: none !important;
}
@media screen and (max-height: 600px) {
  .agtech-search-control-container .agtech-search-control {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-search-control-container .agtech-search-control {
    font-size: 14px;
  }
}
.agtech-home-page {
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.agtech-home-page .agtech-home-page-header {
  flex-direction: row;
  background-color: #878c97;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.06), 0 3px 16px rgba(0, 0, 0, 0.08), 0 4px 24px rgba(0, 0, 0, 0.1);
  height: 30%;
}
.agtech-home-page .agtech-home-page-header .agtech-home-page-header-user-information-section {
  display: flex;
}
.agtech-home-page .agtech-home-page-header .agtech-home-page-header-user-information-section .agtech-home-page-header-user-information-container {
  flex-direction: column;
  justify-content: center;
  color: white;
  padding-left: 20px;
}
.agtech-home-page .agtech-home-page-header .agtech-home-page-header-user-information-section .agtech-home-page-header-user-information-container .agtech-home-page-header-user-information-welcome {
  font-size: 7vh;
  font-weight: 300;
  margin-bottom: 1vh;
}
.agtech-home-page .agtech-home-page-header .agtech-home-page-header-user-information-section .agtech-home-page-header-user-information-container .agtech-home-page-header-user-information-name {
  font-size: 4vh;
  font-weight: 300;
}
.agtech-home-page .agtech-home-page-body {
  flex: 1;
}
.agtech-tabbed-page {
  height: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
}
.agtech-tabbed-page > .agtech-tabbed-page-header {
  flex-direction: row;
  display: flex;
}
.agtech-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-content {
  margin-left: auto;
  align-items: center;
  display: flex;
}
.agtech-tabbed-page.standard > .agtech-tabbed-page-header {
  width: 100%;
  border-bottom: 1px solid #c0c2c5;
  padding-bottom: 12px;
  margin-bottom: 16px;
}
@media screen and (max-height: 600px) {
  .agtech-tabbed-page.standard > .agtech-tabbed-page-header {
    padding-bottom: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-tabbed-page.standard > .agtech-tabbed-page-header {
    padding-bottom: 16px;
  }
}
@media screen and (max-height: 600px) {
  .agtech-tabbed-page.standard > .agtech-tabbed-page-header {
    margin-bottom: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-tabbed-page.standard > .agtech-tabbed-page-header {
    margin-bottom: 16px;
  }
}
.agtech-tabbed-page.standard > .agtech-tabbed-page-header > .agtech-tabbed-page-header-tabs {
  flex-direction: row;
  display: flex;
  height: 32px;
}
.agtech-tabbed-page.standard > .agtech-tabbed-page-header > .agtech-tabbed-page-header-tabs .agtech-tab {
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #64748B;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  padding-left: 16px;
  padding-right: 16px;
  margin-right: 8px;
  min-width: 120px;
}
@media screen and (max-height: 600px) {
  .agtech-tabbed-page.standard > .agtech-tabbed-page-header > .agtech-tabbed-page-header-tabs .agtech-tab {
    font-size: 13px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-tabbed-page.standard > .agtech-tabbed-page-header > .agtech-tabbed-page-header-tabs .agtech-tab {
    font-size: 14px;
  }
}
.agtech-tabbed-page.standard > .agtech-tabbed-page-header > .agtech-tabbed-page-header-tabs .agtech-tab.active {
  background-color: #3278f5;
  color: white;
}
.agtech-tabbed-page .agtech-tabbed-page-body {
  flex: 1;
  overflow: auto;
  display: flex;
}
.agtech-tabbed-page.agtech-badge-tabbed-page > .agtech-tabbed-page-header {
  border-bottom: none;
  padding-top: 1px;
  padding-bottom: 12px;
}
.agtech-tabbed-page.agtech-badge-tabbed-page > .agtech-tabbed-page-header.formal-header {
  border-bottom: 1px solid #c0c2c5;
  padding-bottom: 12px;
  height: 48px !important;
}
.agtech-tabbed-page.agtech-badge-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs {
  align-items: center;
  display: flex;
}
.agtech-tabbed-page.agtech-badge-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab-container.emphasized {
  border-right: 1px solid #c0c2c5;
  padding-right: 16px;
  margin-right: 16px;
}
@media screen and (max-height: 600px) {
  .agtech-tabbed-page.agtech-badge-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab-container.emphasized {
    padding-right: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-tabbed-page.agtech-badge-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab-container.emphasized {
    padding-right: 20px;
  }
}
@media screen and (max-height: 600px) {
  .agtech-tabbed-page.agtech-badge-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab-container.emphasized {
    margin-right: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-tabbed-page.agtech-badge-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab-container.emphasized {
    margin-right: 20px;
  }
}
.agtech-tabbed-page.agtech-badge-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab-container.emphasized .agtech-tab {
  margin-right: 0px;
}
.agtech-tabbed-page.agtech-badge-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab-container .agtech-tab {
  justify-content: center;
  align-items: center;
  border: 1px solid #c0c2c5;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  color: #0f172a;
  font-size: 14px;
  font-weight: 500;
  height: 30px;
  margin-right: 12px;
  min-width: 80px;
}
@media screen and (max-height: 600px) {
  .agtech-tabbed-page.agtech-badge-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab-container .agtech-tab {
    font-size: 13px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-tabbed-page.agtech-badge-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab-container .agtech-tab {
    font-size: 14px;
  }
}
@media screen and (max-height: 600px) {
  .agtech-tabbed-page.agtech-badge-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab-container .agtech-tab {
    height: 30px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-tabbed-page.agtech-badge-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab-container .agtech-tab {
    height: 32px;
  }
}
@media screen and (max-height: 600px) {
  .agtech-tabbed-page.agtech-badge-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab-container .agtech-tab {
    margin-right: 10px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-tabbed-page.agtech-badge-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab-container .agtech-tab {
    margin-right: 16px;
  }
}
.agtech-tabbed-page.agtech-badge-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab-container .agtech-tab:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.agtech-tabbed-page.agtech-badge-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab-container .agtech-tab.active {
  background-color: white;
}
.agtech-tabbed-page.agtech-badge-tabbed-page .agtech-tabbed-page-body {
  overflow: auto;
}
.agtech-tabbed-page.agtech-badge-tabbed-page .agtech-tabbed-page-body .agtech-stretch-upward {
  margin-top: -50px;
}
.agtech-tabbed-page.agtech-badge-tabbed-page.agtech-badge-tabbed-page-underline > .agtech-tabbed-page-header {
  border-bottom: 1px solid #c0c2c5;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.agtech-tabbed-page.agtech-hub-tabbed-page > .agtech-tabbed-page-header {
  border-bottom: none;
}
.agtech-tabbed-page.agtech-hub-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs {
  width: 100%;
  justify-content: center;
  display: flex;
}
.agtech-tabbed-page.agtech-hub-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab {
  color: white;
  min-width: 100px;
  margin-right: 14px;
  border-bottom: 3px solid #e6e6e6;
}
.agtech-tabbed-page.agtech-hub-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab:last-child {
  margin-right: 0px;
}
.agtech-tabbed-page.agtech-hub-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab .agtech-tab-text {
  font-size: 14px;
}
@media screen and (max-height: 600px) {
  .agtech-tabbed-page.agtech-hub-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab .agtech-tab-text {
    font-size: 13px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-tabbed-page.agtech-hub-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab .agtech-tab-text {
    font-size: 14px;
  }
}
.agtech-tabbed-page.agtech-hub-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab:hover {
  border-bottom: 3px solid #c0c2c5;
}
.agtech-tabbed-page.agtech-hub-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab.active {
  border-bottom: 3px solid #3278f5;
}
.agtech-tabbed-page.agtech-hub-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab.active .agtech-tab-text {
  font-size: 15px;
}
@media screen and (max-height: 600px) {
  .agtech-tabbed-page.agtech-hub-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab.active .agtech-tab-text {
    font-size: 14px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-tabbed-page.agtech-hub-tabbed-page > .agtech-tabbed-page-header .agtech-tabbed-page-header-tabs .agtech-tab.active .agtech-tab-text {
    font-size: 16px;
  }
}
.agtech-multi-page-tabbed-page .agtech-tabbed-page-body {
  padding-top: 20px;
}
@media screen and (max-height: 600px) {
  .agtech-multi-page-tabbed-page .agtech-tabbed-page-body {
    padding-top: 16px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-multi-page-tabbed-page .agtech-tabbed-page-body {
    padding-top: 20px;
  }
}
.agtech-multi-page-header-tab {
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  color: #64748B;
  font-weight: 500;
  font-size: 13px;
  margin-right: 8px;
  display: flex;
  padding-bottom: 9px;
  border-bottom: 2px solid transparent;
  min-width: 100px;
  margin-top: 1px;
}
@media screen and (max-height: 600px) {
  .agtech-multi-page-header-tab {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-multi-page-header-tab {
    font-size: 14px;
  }
}
.agtech-multi-page-header-tab:hover {
  color: #3278f5;
}
.agtech-multi-page-header-tab.active {
  color: #0f172a;
  border-bottom: 2px solid #3278f5;
}
.agtech-multi-page-header-tab.active .agtech-tab {
  color: white;
}
.agtech-no-records-error-page .agtech-button {
  background-color: #3278f5;
  color: white;
}
.agtech-no-records-error-page .agtech-button:hover {
  background-color: #366dc7;
}
.agtech-no-records-error-page .agtech-button:focus {
  background-color: #366dc7;
}
.agtech-horizonal-element-collection {
  height: 70%;
  flex-direction: row;
  align-items: center;
  display: flex;
}
.agtech-horizonal-element-collection > * {
  height: 100%;
  padding-left: 24px;
  padding-right: 24px;
  border-right: 1px solid #c0c2c5;
}
.agtech-horizonal-element-collection > *:last-child {
  border: none;
}
@keyframes PopUpFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.agtech-popup {
  position: fixed;
  z-index: 10;
  opacity: 0;
  padding-left: 8px;
  padding-right: 8px;
}
.agtech-popup.placed {
  animation-name: PopUpFadeIn;
  animation-fill-mode: forwards;
}
.agtech-popup .agtech-popup-body {
  border: 1px solid #c0c2c5;
  border-radius: 4px;
  background-color: white;
  display: flex;
}
.agtech-popup .agtech-popup-menu {
  min-width: 160px;
}
.agtech-popup .agtech-popup-menu .agtech-popup-menu-section {
  border-bottom: 1px solid #c0c2c5;
  padding: 12px;
  padding-bottom: 4px;
}
.agtech-popup .agtech-popup-menu .agtech-popup-menu-section .agtech-popup-menu-section-title {
  font-size: 13px;
  padding-left: 2px;
  padding-bottom: 8px;
  font-weight: 500;
}
@media screen and (max-height: 600px) {
  .agtech-popup .agtech-popup-menu .agtech-popup-menu-section .agtech-popup-menu-section-title {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-popup .agtech-popup-menu .agtech-popup-menu-section .agtech-popup-menu-section-title {
    font-size: 14px;
  }
}
.agtech-popup .agtech-popup-menu .agtech-popup-menu-section .agtech-button {
  margin: 0px;
  border: none;
  background-color: transparent;
  font-size: 13px;
  color: #64748B;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #e6e6e6;
}
@media screen and (max-height: 600px) {
  .agtech-popup .agtech-popup-menu .agtech-popup-menu-section .agtech-button {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-popup .agtech-popup-menu .agtech-popup-menu-section .agtech-button {
    font-size: 14px;
  }
}
.agtech-popup .agtech-popup-menu .agtech-popup-menu-section .agtech-button:last-child {
  border-bottom: none;
}
.agtech-popup .agtech-popup-menu .agtech-popup-menu-section .agtech-button:hover {
  color: #3278f5;
}
.agtech-popup .agtech-popup-menu .agtech-popup-menu-section .agtech-button .agtech-button-icon {
  line-height: inherit;
  width: 16px;
}
.agtech-popup.loaded {
  opacity: 1;
}
.agtech-popup-holder {
  cursor: pointer;
  display: flex;
}
.agtech-title-detail-popup {
  flex: 1;
}
.agtech-title-detail-popup .agtech-title-detail-popup-header {
  padding: 8px;
  border-bottom: 1px solid #c0c2c5;
  background-color: #f6f7fb;
}
.agtech-title-detail-popup .agtech-title-detail-popup-body {
  padding: 8px;
}
.agtech-actions-icon {
  font-size: 13px;
  color: #64748B;
}
@media screen and (max-height: 600px) {
  .agtech-actions-icon {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-actions-icon {
    font-size: 14px;
  }
}
.agtech-actions-icon:hover {
  color: #0f172a;
}
.agtech-step-by-step-page-surface {
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 4px;
}
.agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-header {
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #c0c2c5;
  padding: 8px;
}
.agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-header .agtech-step-by-step-page-surface-header-body {
  flex-direction: row;
  flex: 1;
  max-width: 75%;
}
.agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-header .agtech-step-by-step-page-surface-header-body .agtech-step-by-step-page-header-step {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 72px;
  overflow: visible;
}
.agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-header .agtech-step-by-step-page-surface-header-body .agtech-step-by-step-page-header-step.current .agtech-icon-badge {
  border: 1px solid #c0c2c5;
  background-color: #eff7fb;
}
.agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-header .agtech-step-by-step-page-surface-header-body .agtech-step-by-step-page-header-step.current .agtech-step-by-step-page-header-step-name {
  font-weight: 500;
}
.agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-header .agtech-step-by-step-page-surface-header-body .agtech-step-by-step-page-header-step.started .agtech-icon-badge {
  background-color: #eff7fb;
}
.agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-header .agtech-step-by-step-page-surface-header-body .agtech-step-by-step-page-header-step.completed .agtech-icon-badge {
  background-color: #d3ffd3;
}
.agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-header .agtech-step-by-step-page-surface-header-body .agtech-step-by-step-page-header-step .agtech-icon-badge {
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 4px;
  font-size: 13px;
  border-radius: 4px;
  height: 28px;
  width: 28px;
}
@media screen and (max-height: 600px) {
  .agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-header .agtech-step-by-step-page-surface-header-body .agtech-step-by-step-page-header-step .agtech-icon-badge {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-header .agtech-step-by-step-page-surface-header-body .agtech-step-by-step-page-header-step .agtech-icon-badge {
    font-size: 14px;
  }
}
.agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-header .agtech-step-by-step-page-surface-header-body .agtech-step-by-step-page-header-step .agtech-step-by-step-page-header-step-name {
  font-size: 12px;
  white-space: nowrap;
}
@media screen and (max-height: 600px) {
  .agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-header .agtech-step-by-step-page-surface-header-body .agtech-step-by-step-page-header-step .agtech-step-by-step-page-header-step-name {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-header .agtech-step-by-step-page-surface-header-body .agtech-step-by-step-page-header-step .agtech-step-by-step-page-header-step-name {
    font-size: 13px;
  }
}
.agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-header .agtech-step-by-step-page-surface-header-body .agtech-step-by-step-page-header-step-divider {
  flex: 1;
  background-color: #878c97;
  height: 2px;
  margin-top: 20px;
}
.agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-header .agtech-step-by-step-page-surface-header-body .agtech-step-by-step-page-header-step-divider:last-child {
  display: none;
}
.agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-header .agtech-step-by-step-page-surface-header-body .agtech-step-by-step-page-header-names {
  flex-direction: row;
  width: 100%;
}
.agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-body {
  flex: 1;
  overflow: hidden;
  background-color: #e9ebef;
  padding: 12px;
}
.agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-body .agtech-step-by-step-page-surface-body-container {
  overflow: auto;
  height: 100%;
}
.agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-body .agtech-step-by-step-page-surface-body-container form {
  height: 100%;
}
.agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-body .agtech-step-by-step-page-surface-body-container .agtech-step-by-step-page-step-surface {
  height: 100%;
}
.agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-footer {
  flex-direction: row;
  border-top: 1px solid #c0c2c5;
  padding: 8px;
  height: 60px;
}
.agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-footer .agtech-button {
  font-weight: 500;
  padding-right: 12px;
  padding-left: 8px;
}
.agtech-step-by-step-page-surface .agtech-step-by-step-page-surface-footer .agtech-button.agtech-step-by-step-cancel-button {
  border: 1px solid #ac2222;
}
@keyframes toastFadeIn {
  0% {
    opacity: 0;
    transform: translateX(0px);
  }
  100% {
    opacity: 1;
    transform: translateX(20px);
  }
}
@keyframes toastFadeOut {
  0% {
    opacity: 1;
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    transform: translateX(0px);
    height: 0px;
    margin-top: 0px;
  }
}
.agtech-toast-surface {
  position: absolute;
  display: none;
  height: 100%;
  bottom: 0;
  left: 0;
  width: 600px;
  padding-top: 24px;
  padding-bottom: 24px;
  z-index: 12;
}
.agtech-toast-surface.show {
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
}
.agtech-toast-surface .agtech-rendered-toast {
  position: relative;
  border-radius: 4px;
  background-color: white;
  margin-top: 8px;
  overflow: hidden;
  animation-duration: 500ms;
  animation-name: toastFadeIn;
  animation-fill-mode: forwards;
  transition: all 500ms linear;
}
.agtech-toast-surface .agtech-rendered-toast.faded {
  animation-duration: 500ms;
  animation-name: toastFadeOut;
  animation-fill-mode: forwards;
}
.agtech-toast-surface .agtech-rendered-toast .agtech-toast {
  flex-direction: row;
  align-items: center;
  border: 1px solid #c0c2c5;
  border-radius: 4px;
  background-color: white;
  width: 100%;
  overflow: auto;
  padding-left: 8px;
  padding-right: 16px;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.06), 0 3px 16px rgba(0, 0, 0, 0.08), 0 4px 24px rgba(0, 0, 0, 0.1);
  display: flex;
  padding-right: 48px;
  min-height: 86px;
  max-height: 300px;
}
.agtech-toast-surface .agtech-rendered-toast .agtech-toast .agtech-toast-indicator {
  width: 6px;
  height: 100%;
  min-height: 70px;
  background-color: #3278f5;
  border-radius: 10px;
}
.agtech-toast-surface .agtech-rendered-toast .agtech-toast .agtech-toast-icon {
  margin-left: 16px;
  margin-right: 16px;
}
.agtech-toast-surface .agtech-rendered-toast .agtech-toast.toast-info {
  border-color: #3278f5;
}
.agtech-toast-surface .agtech-rendered-toast .agtech-toast.toast-warning {
  border: 1px solid #efa928;
}
.agtech-toast-surface .agtech-rendered-toast .agtech-toast.toast-warning .agtech-toast-indicator {
  background-color: #efa928;
}
.agtech-toast-surface .agtech-rendered-toast .agtech-toast.toast-warning .agtech-toast-icon {
  background-color: #efa928;
  color: white;
}
.agtech-toast-surface .agtech-rendered-toast .agtech-toast.toast-error {
  border: 1px solid #ac2222;
}
.agtech-toast-surface .agtech-rendered-toast .agtech-toast.toast-error .agtech-toast-indicator {
  background-color: #ac2222;
}
.agtech-toast-surface .agtech-rendered-toast .agtech-toast.toast-error .agtech-toast-icon {
  background-color: #ac2222;
  color: white;
}
.agtech-toast-surface .agtech-rendered-toast .agtech-toast.toast-success {
  border: 1px solid #11a311;
}
.agtech-toast-surface .agtech-rendered-toast .agtech-toast.toast-success .agtech-toast-indicator {
  background-color: #11a311;
}
.agtech-toast-surface .agtech-rendered-toast .agtech-toast.toast-success .agtech-toast-icon {
  background-color: #11a311;
  color: white;
}
.agtech-toast-surface .agtech-rendered-toast .agtech-toast .agtech-toast-header {
  font-size: 15px;
  font-weight: 500;
}
@media screen and (max-height: 600px) {
  .agtech-toast-surface .agtech-rendered-toast .agtech-toast .agtech-toast-header {
    font-size: 14px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-toast-surface .agtech-rendered-toast .agtech-toast .agtech-toast-header {
    font-size: 16px;
  }
}
.agtech-toast-surface .agtech-rendered-toast .agtech-toast .agtech-toast-body {
  margin-top: 8px;
  overflow-y: auto;
  overflow-x: hidden;
}
.agtech-toast-surface .agtech-rendered-toast .agtech-toast .agtech-toast-body .agtech-toast-details {
  font-size: 13px;
  font-weight: 400;
}
@media screen and (max-height: 600px) {
  .agtech-toast-surface .agtech-rendered-toast .agtech-toast .agtech-toast-body .agtech-toast-details {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-toast-surface .agtech-rendered-toast .agtech-toast .agtech-toast-body .agtech-toast-details {
    font-size: 14px;
  }
}
.agtech-toast-surface .agtech-rendered-toast .agtech-toast-removal-icon {
  position: absolute;
  cursor: pointer;
  color: #64748B;
  font-size: 15px;
  top: 20px;
  right: 24px;
}
@media screen and (max-height: 600px) {
  .agtech-toast-surface .agtech-rendered-toast .agtech-toast-removal-icon {
    font-size: 14px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-toast-surface .agtech-rendered-toast .agtech-toast-removal-icon {
    font-size: 16px;
  }
}
.agtech-toast-surface .agtech-rendered-toast .agtech-toast-removal-icon:hover {
  color: #0f172a;
}
.agtech-todo-list {
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
}
.agtech-todo-list .agtech-todo-list-item {
  flex-direction: row;
  padding: 2px;
  padding-left: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  border-left: 4px solid rgba(50, 120, 245, 0.4);
}
.agtech-todo-list .agtech-todo-list-item:first-child {
  margin-top: 0px;
}
.agtech-todo-list .agtech-todo-list-item.completed .agtech-header-title {
  text-decoration: line-through;
}
.agtech-todo-list .agtech-todo-list-item.completed .agtech-header-details {
  text-decoration: line-through;
}
.agtech-todo-list .agtech-todo-list-item .agtech-checkbox {
  width: 20px;
  margin-right: 12px;
}
.agtech-todo-list .agtech-todo-list-item .agtech-header-title {
  font-size: 14px;
  white-space: break-spaces;
}
@media screen and (max-height: 600px) {
  .agtech-todo-list .agtech-todo-list-item .agtech-header-title {
    font-size: 13px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-todo-list .agtech-todo-list-item .agtech-header-title {
    font-size: 14px;
  }
}
.agtech-todo-list .agtech-todo-list-item .agtech-header-details {
  margin-top: 2px !important;
}
.agtech-todo-list .agtech-todo-list-item.priority-medium {
  border-left: 4px solid #808036;
}
.agtech-todo-list .agtech-todo-list-item.priority-high {
  border-left: 4px solid #ac2222;
}
.agtech-text-box {
  width: 100%;
  font-size: 13px;
  color: #64748B;
  border: none;
  padding: 0px;
  outline: none !important;
  height: 95%;
  resize: none;
}
@media screen and (max-height: 600px) {
  .agtech-text-box {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-text-box {
    font-size: 14px;
  }
}
.agtech-text-control {
  flex: 1;
  padding-left: 4px;
  padding-right: 4px;
}
.agtech-text-control:first-child {
  padding-left: 0px;
}
.agtech-text-control:last-child {
  padding-right: 0px;
}
.agtech-text-control .agtech-text-control-caption {
  font-size: 12px;
  font-weight: 500;
  margin-left: 1px;
  margin-bottom: 5px;
}
@media screen and (max-height: 600px) {
  .agtech-text-control .agtech-text-control-caption {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-text-control .agtech-text-control-caption {
    font-size: 13px;
  }
}
.agtech-calendar {
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.agtech-calendar .agtech-calendar-header {
  flex-direction: row;
  padding-bottom: 4px;
}
.agtech-calendar .agtech-calendar-header .agtech-calendar-month-control {
  justify-content: center;
  align-items: center;
}
.agtech-calendar .agtech-calendar-header .agtech-calendar-month-control .agtech-button {
  min-height: 0px;
}
.agtech-calendar .agtech-calendar-header .agtech-calendar-month-control .agtech-calendar-month {
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-left: 4px;
  margin-right: 8px;
  width: 160px;
}
@media screen and (max-height: 600px) {
  .agtech-calendar .agtech-calendar-header .agtech-calendar-month-control .agtech-calendar-month {
    font-size: 15px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-calendar .agtech-calendar-header .agtech-calendar-month-control .agtech-calendar-month {
    font-size: 17px;
  }
}
.agtech-calendar .agtech-calendar-body {
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding-top: 4px;
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-days-of-week-header {
  flex-direction: column;
  justify-content: center;
  background-color: #366dc7;
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-days-of-week-header .agtech-calendar-day-of-week {
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 4px;
  color: white;
  border-right: 1px solid white;
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-days-of-week-header .agtech-calendar-day-of-week:first-child {
  border-left: 1px solid #366dc7;
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-week {
  flex: 1;
  border-top: 1px solid #c0c2c5;
  overflow: hidden;
  flex-wrap: nowrap;
  display: flex;
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-week:last-child .agtech-calendar-day {
  border-bottom: 1px solid #c0c2c5;
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day {
  flex-direction: column;
  flex: 1;
  background-color: white;
  overflow: hidden;
  border-left: 1px solid #c0c2c5;
  padding: 4px 6px;
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day.highlight {
  background-color: #eff7fb;
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day .agtech-calendar-day-body {
  -ms-overflow-style: none;
  /* Internet Explorer and Edge */
  scrollbar-width: none;
  /* Fir

                    /* Hide the scrollbar for Chrome, Safari and Opera */
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day .agtech-calendar-day-body::-webkit-scrollbar {
  display: none;
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day.agtech-calendar-blank-day {
  background-color: #fcfcfc;
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day.agtech-calendar-blank-day .agtech-calendar-day-header .agtech-calendar-day-header-day {
  color: #64748B;
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day .agtech-calendar-day-header .row-based-vertically-centered .agtech-calendar-day-header-day {
  font-size: 12px;
  color: #0f172a;
}
@media screen and (max-height: 600px) {
  .agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day .agtech-calendar-day-header .row-based-vertically-centered .agtech-calendar-day-header-day {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day .agtech-calendar-day-header .row-based-vertically-centered .agtech-calendar-day-header-day {
    font-size: 13px;
  }
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day .agtech-calendar-day-body {
  flex: 1;
  overflow: auto;
  margin-top: 2px;
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day:last-of-type {
  border-right: 1px solid #c0c2c5;
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day .agtech-calendar-event-badge {
  color: white;
  border-radius: 4px;
  cursor: pointer;
  padding: 3px 4px;
  padding-top: 1px;
  margin-bottom: 3px;
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day .agtech-calendar-event-badge h1 {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media screen and (max-height: 600px) {
  .agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day .agtech-calendar-event-badge h1 {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day .agtech-calendar-event-badge h1 {
    font-size: 13px;
  }
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day .agtech-calendar-event-badge.agtech-calendar-event-blue {
  background-color: #3278f5;
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day .agtech-calendar-event-badge.agtech-calendar-event-red {
  background-color: #ac2222;
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day .agtech-calendar-event-badge.agtech-calendar-event-yellow {
  background-color: #808036;
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day .agtech-calendar-event-badge.agtech-calendar-event-green {
  background-color: darkgreen;
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day .agtech-calendar-event-badge.agtech-calendar-event-gray {
  background-color: #878c97;
}
.agtech-calendar .agtech-calendar-body .agtech-calendar-week .agtech-calendar-day .agtech-calendar-event-badge.agtech-calendar-event-orange {
  background-color: #bf7c00;
}
.agtech-calendar .agtech-calendar-footer {
  flex-direction: row;
}
.agtech-kanban-board {
  height: 100%;
  width: 100%;
  flex-direction: row;
}
.agtech-kanban-board .agtech-kanban-board-section {
  flex: 1;
  overflow: hidden;
  border-right: 1px solid #c0c2c5;
  padding: 0px 8px;
}
.agtech-kanban-board .agtech-kanban-board-section:first-child {
  padding-left: 0px;
}
.agtech-kanban-board .agtech-kanban-board-section:last-child {
  padding-right: 0px;
  border-right: none;
}
.agtech-horizontal-divider {
  height: calc(100% - 4px * 2);
  width: 1px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: #c0c2c5;
}
.agtech-vertical-divider {
  width: 100%;
  height: 1px;
  margin: 6px 0px;
  background-color: #c0c2c5;
}
/* width */
::-webkit-scrollbar {
  width: 3px !important;
  height: 6px;
  background-color: transparent;
  color: red;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
  width: 1px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(150, 150, 150, 0.5) !important;
  border-radius: 8px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}
.agtech-portal-backdrop {
  justify-content: center;
  align-items: center;
  background-color: #e9ebef;
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 400ms;
  z-index: 10;
}
.agtech-portal-backdrop.active {
  opacity: 1;
}
.agtech-fade-in {
  animation: fadeIn 200ms;
}
.agtech-fade-out {
  animation: fadeOut 200ms;
}
.agtech-loading-surface {
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: 1;
}
.agtech-loading-surface .agtech-loader-text {
  font-size: 16px;
  font-weight: 500;
  opacity: 0;
  animation: fadeIn 300ms forwards 0ms;
  margin-top: 20px;
}
@media screen and (max-height: 600px) {
  .agtech-loading-surface .agtech-loader-text {
    font-size: 15px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-loading-surface .agtech-loader-text {
    font-size: 17px;
  }
}
.loader {
  height: 36px;
  width: 36px;
  color: #3278f5;
  border: 4px solid transparent;
  border-top: 4px solid;
  border-left: 4px solid;
  border-radius: 50%;
  animation: spin 2s linear infinite, fadeIn 300ms forwards;
}
.fade-in {
  opacity: 0;
  animation: fadeIn 400ms forwards 0ms;
}
.fade-in-300 {
  opacity: 0;
  animation: fadeIn 300ms forwards 0ms;
}
.fade-in-400 {
  opacity: 0;
  animation: fadeIn 400ms forwards 0ms;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes moveUp {
  0% {
    transform: none;
  }
  100% {
    transform: translate3d(0, -220px, 0);
  }
}
.agtech-horizontal-padding > div {
  margin-right: 16px;
}
.agtech-horizontal-padding > div:last-child {
  margin-right: 0px;
}
.agtech-horizontal-element-container {
  flex-direction: column;
  justify-content: center;
}
.agtech-horizontal-element-container button {
  margin-right: 6px;
}
.agtech-horizontal-element-container button:last-child {
  margin-right: 0px;
}
.agtech-calendar-heat-map {
  background-color: white;
  border: 1px solid #c0c2c5;
  height: fit-content;
}
.agtech-calendar-heat-map .agtech-calendar-heat-map-months {
  height: 20px;
  border-bottom: 1px solid #c0c2c5;
}
.agtech-calendar-heat-map .agtech-calendar-heat-map-months .row-based-vertically-centered h1 {
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #c0c2c5;
  font-size: 12px;
  width: 8.5%;
}
@media screen and (max-height: 600px) {
  .agtech-calendar-heat-map .agtech-calendar-heat-map-months .row-based-vertically-centered h1 {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-calendar-heat-map .agtech-calendar-heat-map-months .row-based-vertically-centered h1 {
    font-size: 13px;
  }
}
.agtech-calendar-heat-map .agtech-calendar-heat-map-months .row-based-vertically-centered h1:last-child {
  border-right: none;
}
.agtech-calendar-heat-map .agtech-heat-map-column {
  flex: 1;
  border-right: 1px solid #c0c2c5;
}
.agtech-calendar-heat-map .agtech-heat-map-column:last-child {
  border-right: none;
}
.agtech-calendar-heat-map .agtech-heat-map-column .agtech-heat-map-day {
  height: 20px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #c0c2c5;
}
.agtech-calendar-heat-map .agtech-heat-map-column .agtech-heat-map-day.populated {
  background-color: #3278f5;
}
.agtech-calendar-heat-map .agtech-heat-map-column .agtech-heat-map-day h1 {
  font-size: 12px;
}
@media screen and (max-height: 600px) {
  .agtech-calendar-heat-map .agtech-heat-map-column .agtech-heat-map-day h1 {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-calendar-heat-map .agtech-heat-map-column .agtech-heat-map-day h1 {
    font-size: 13px;
  }
}
.agtech-calendar-heat-map .agtech-heat-map-column .agtech-heat-map-day:last-child {
  border-bottom: none;
}
.agtech-section-container {
  flex-direction: column;
}
.agtech-section-container:last-child .agtech-section-body {
  border-bottom: none;
}
.agtech-section-container .agtech-section-header {
  flex-direction: row;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  border-bottom: 1px solid #c0c2c5;
  padding-left: 8px;
  padding-right: 8px;
  background-color: #f6f6f6;
}
@media screen and (max-height: 600px) {
  .agtech-section-container .agtech-section-header {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-section-container .agtech-section-header {
    font-size: 13px;
  }
}
.agtech-section-container .agtech-section-body {
  flex: 1;
  overflow: hidden;
  border-bottom: 1px solid #c0c2c5;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}
.agtech-section-container .agtech-section-body.compact {
  padding: 4px;
}
.agtech-section-container .agtech-section-body .agtech-no-records-error-page .agtech-no-records-error-message {
  font-size: 14px;
  margin-bottom: 4px;
}
@media screen and (max-height: 600px) {
  .agtech-section-container .agtech-section-body .agtech-no-records-error-page .agtech-no-records-error-message {
    font-size: 13px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-section-container .agtech-section-body .agtech-no-records-error-page .agtech-no-records-error-message {
    font-size: 14px;
  }
}
.agtech-section-container .agtech-section-body .agtech-no-records-error-page .agtech-no-records-action-button .agtech-button {
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}
@media screen and (max-height: 600px) {
  .agtech-section-container .agtech-section-body .agtech-no-records-error-page .agtech-no-records-action-button .agtech-button {
    font-size: 13px;
  }
}
@media screen and (min-height: 1000px) {
  .agtech-section-container .agtech-section-body .agtech-no-records-error-page .agtech-no-records-action-button .agtech-button {
    font-size: 14px;
  }
}
.agtech-growing-container {
  transition: max-height 300ms;
}
.grow-width {
  transition: width 200ms linear;
}
.agtech-selection-popup {
  flex-direction: column;
  overflow: hidden;
  border-radius: inherit;
  display: flex;
}
.agtech-selection-popup .agtech-selection-list-header {
  flex-direction: row;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  background-color: white;
  border-bottom: 1px solid #c0c2c5;
  border-color: #88939c;
  display: flex;
  border: 1px solid transparent;
}
.agtech-selection-popup .agtech-selection-list-header .agtech-button {
  min-height: 28px;
  height: 28px;
  line-height: 28px;
}
.agtech-selection-popup .agtech-selection-list-body {
  flex: 1;
  overflow: hidden;
  display: flex;
}
.agtech-selection-popup .agtech-selection-list-body .agtech-selection-list-item {
  width: 100%;
  border-bottom: 1px solid #c0c2c5;
  border-color: #e6e6e6;
  flex-direction: row;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  border: 1px solid transparent;
}
.agtech-selection-popup .agtech-selection-list-body .agtech-selection-list-item.even {
  background-color: #fcfcfc;
}
.agtech-selection-popup .agtech-selection-list-body .agtech-selection-list-item:hover {
  background-color: #eff7fb;
}
.agtech-selection-popup .agtech-selection-list-body .agtech-selection-list-item.selected {
  background-color: #e5f7ff;
  border-color: #3278f5;
}
.agtech-selection-popup .agtech-selection-list-footer {
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #c0c2c5;
  border-color: #88939c;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
}
.container {
  display: flex;
}
.block {
  display: block;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}
.absolute {
  position: absolute;
}
.flex-shrink {
  flex: 0;
}
.flexed {
  flex: 1;
}
.flexed-40 {
  flex: 4;
}
.flexed-60 {
  flex: 6;
}
.row-based {
  flex-direction: column;
}
.row-based-horizontally-centered {
  flex-direction: column;
  align-items: center;
}
.row-based-vertically-centered {
  flex-direction: column;
  justify-content: center;
}
.column-based {
  flex-direction: row;
}
.column-based-vertically-centered {
  flex-direction: row;
  align-items: center;
}
.wrappable {
  flex-wrap: wrap;
}
.stretched {
  height: 100%;
  width: 100%;
}
.tall {
  height: 100%;
}
.wide {
  width: 100%;
}
.vertically-centered {
  align-items: center;
}
.horizontally-centered {
  justify-content: center;
}
.centered {
  justify-content: center;
  align-items: center;
}
.scrollable {
  overflow: auto;
}
.scrollable-vertical {
  overflow-y: auto;
  overflow-x: hidden;
}
.scrollbar-hidden {
  -ms-overflow-style: none;
  /* Internet Explorer and Edge */
  /* Hide the scrollbar for Chrome, Safari and Opera */
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
.scrollbar-white ::-webkit-scrollbar-track {
  background: white !important;
  width: 1px;
}
.non-scrollable {
  overflow: hidden;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.display-none {
  display: none;
}
.display-block {
  display: block;
}
.absolute-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.snug {
  padding: 0px;
  margin: 0px;
}
.grid {
  display: grid;
}
.grid-gap-4 {
  grid-gap: 16px;
}
.no-padding {
  padding: 0px;
}
.padded {
  padding: 4px;
}
.double-padded {
  padding: 8px;
}
.p-0 {
  padding: 0px;
}
.p-1 {
  padding: 4px;
}
.p-2 {
  padding: 8px;
}
.p-2-half {
  padding: 10px;
}
.p-3 {
  padding: 12px;
}
.p-4 {
  padding: 16px;
}
.p-5 {
  padding: 20px;
}
.p-6 {
  padding: 24px;
}
.p-7 {
  padding: 28px;
}
.px-half {
  padding-left: 2px;
  padding-right: 2px;
}
.px-1 {
  padding-left: 4px;
  padding-right: 4px;
}
.px-2 {
  padding-left: 8px;
  padding-right: 8px;
}
.px-2-half {
  padding-left: 10px;
  padding-right: 10px;
}
.px-3 {
  padding-left: 12px;
  padding-right: 12px;
}
.px-3-half {
  padding-left: 14px;
  padding-right: 14px;
}
.px-4 {
  padding-left: 16px;
  padding-right: 16px;
}
.px-5 {
  padding-left: 20px;
  padding-right: 20px;
}
.px-6 {
  padding-left: 24px;
  padding-right: 24px;
}
.px-7 {
  padding-left: 28px;
  padding-right: 28px;
}
.px-8 {
  padding-left: 32px;
  padding-right: 32px;
}
.py-half {
  padding-top: 2px;
  padding-bottom: 2px;
}
.py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.py-2-half {
  padding-top: 10px;
  padding-bottom: 10px;
}
.py-3 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.py-4 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.py-5 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.py-6 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.py-7 {
  padding-top: 28px;
  padding-bottom: 28px;
}
.pt-1 {
  padding-top: 4px;
}
.pt-2 {
  padding-top: 8px;
}
.pt-2-half {
  padding-top: 10px;
}
.pt-3 {
  padding-top: 12px;
}
.pt-4 {
  padding-top: 16px;
}
.pt-5 {
  padding-top: 20px;
}
.pt-6 {
  padding-top: 24px;
}
.pb-0 {
  padding-bottom: 0px;
}
.pb-half {
  padding-bottom: 2px;
}
.pb-1 {
  padding-bottom: 4px;
}
.pb-1-half {
  padding-bottom: 6px;
}
.pb-2 {
  padding-bottom: 8px;
}
.pb-2-half {
  padding-bottom: 10px;
}
.pb-3 {
  padding-bottom: 12px;
}
.pb-4 {
  padding-bottom: 16px;
}
.pb-4-half {
  padding-bottom: 18px;
}
.pb-5 {
  padding-bottom: 20px;
}
.pb-6 {
  padding-bottom: 24px;
}
.pr-0 {
  padding-right: 0px;
}
.pr-half {
  padding-right: 2px;
}
.pr-1 {
  padding-right: 4px;
}
.pr-2 {
  padding-right: 8px;
}
.pr-3 {
  padding-right: 12px;
}
.pr-4 {
  padding-right: 16px;
}
.pr-5 {
  padding-right: 20px;
}
.pr-6 {
  padding-right: 24px;
}
.pl-half {
  padding-left: 2px;
}
.pl-1 {
  padding-left: 4px;
}
.pl-1-half {
  padding-left: 6px;
}
.pl-2 {
  padding-left: 8px;
}
.pl-3 {
  padding-left: 12px;
}
.pl-4 {
  padding-left: 16px;
}
.pl-5 {
  padding-left: 20px;
}
.pl-6 {
  padding-left: 24px;
}
.m-0 {
  margin: 0px;
}
.mx-1 {
  margin-left: 4px;
  margin-right: 8px;
}
.mx-2 {
  margin-left: 8px;
  margin-right: 8px;
}
.mx-3 {
  margin-left: 12px;
  margin-right: 12px;
}
.mx-4 {
  margin-left: 16px;
  margin-right: 16px;
}
.mx-5 {
  margin-left: 20px;
  margin-right: 20px;
}
.my-half {
  margin-top: 2px;
  margin-bottom: 2px;
}
.my-1 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.mt-half {
  margin-top: 2px;
}
.mt-1 {
  margin-top: 4px;
}
.mt-1-f {
  margin-top: 4px !important;
}
.mt-1-half {
  margin-top: 6px;
}
.mt-2 {
  margin-top: 8px;
}
.mt-2-half {
  margin-top: 10px;
}
.mt-3 {
  margin-top: 12px;
}
.mt-4 {
  margin-top: 16px;
}
.mt-5 {
  margin-top: 20px;
}
.mt-6 {
  margin-top: 24px;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.mb-half {
  margin-bottom: 2px;
}
.mb-1 {
  margin-bottom: 4px;
}
.mb-1-half {
  margin-bottom: 6px;
}
.mb-2 {
  margin-bottom: 8px;
}
.mb-3 {
  margin-bottom: 12px;
}
.mb-4 {
  margin-bottom: 16px;
}
.mb-5 {
  margin-bottom: 20px;
}
.mb-6 {
  margin-bottom: 24px;
}
.ml-auto {
  margin-left: auto;
}
.ml-sub-1 {
  margin-left: -1px;
}
.ml-half {
  margin-left: 2px;
}
.ml-1 {
  margin-left: 4px;
}
.ml-2 {
  margin-left: 8px;
}
.ml-3 {
  margin-left: 12px;
}
.ml-4 {
  margin-left: 16px;
}
.ml-5 {
  margin-left: 20px;
}
.ml-6 {
  margin-left: 24px;
}
.mr-1 {
  margin-right: 8px;
}
.mr-2 {
  margin-right: 8px;
}
.mr-3 {
  margin-right: 12px;
}
.mr-3-half {
  margin-right: 14px;
}
.mr-4 {
  margin-right: 16px;
}
.mr-5 {
  margin-right: 20px;
}
.mr-6 {
  margin-right: 24px;
}
.mr-4-when-not-last {
  margin-right: 16px;
}
.mr-4-when-not-last:last-child {
  margin-right: 0px;
}
.area-left {
  margin-right: 16px;
}
.area-right {
  margin-left: 16px;
}
.no-outline {
  outline: none !important;
}
.bordered {
  border: 1px solid #c0c2c5;
}
.bordered-highlight-on-selected-not-selected {
  border: 1px solid transparent;
}
.bordered-highlight-on-selected-is-selected {
  border-color: #3278f5;
}
.non-bordered {
  border: none;
}
.non-bordered-f {
  border: none !important;
}
.non-top-bordered {
  border-top: none;
}
.left-selection-bordered-not-selected {
  border-left: 3px solid transparent;
}
.left-selection-bordered-selected {
  border-left: 3px solid #3278f5;
}
.top-bordered {
  border-top: 1px solid #c0c2c5;
}
.top-bordered-when-not-first {
  border-top: 1px solid #c0c2c5;
}
.top-bordered-when-not-first:first-child {
  border-top: none;
}
.bottom-bordered {
  border-bottom: 1px solid #c0c2c5;
}
.bottom-bordered-when-not-last {
  border-bottom: 1px solid #c0c2c5;
}
.bottom-bordered-when-not-last:last-child {
  border: none;
}
.bottom-bordered-when-last:last-child {
  border-bottom: 1px solid #c0c2c5;
}
.right-bordered {
  border-right: 1px solid #c0c2c5;
}
.right-bordered-when-last:last-child {
  border-right: 1px solid #c0c2c5;
}
.left-bordered {
  border-left: 1px solid #c0c2c5;
}
.highlight-bordered {
  border-color: #3278f5;
}
.clear-bordered {
  border-color: transparent;
}
.table-bordered {
  border-color: #e6e6e6;
}
.dark-bordered {
  border-color: #88939c;
}
.yellow-bordered {
  border-color: yellow;
}
.left-bordered-transparent {
  border-left: 3px solid transparent;
}
.left-bordered-highlight {
  border-left: 3px solid #3278f5;
}
.left-bordered-thick-highlight {
  border-left: 3px solid #3278f5;
}
.square {
  border-radius: 0px;
}
.rounded {
  border-radius: 4px;
}
.rounded-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.rounded-bottom {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.rounded-light {
  border-radius: 2px;
}
.rounded-heavy {
  border-radius: 8px;
}
.rounded-circle {
  border-radius: 50%;
}
.rounded-inherit {
  border-radius: inherit;
}
.rounded-top-inherit {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.rounded-bottom-inherit {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.thick-bordered {
  border-width: 2px;
}
.font-height-1 {
  line-height: 1;
}
.font-height-2 {
  line-height: 2;
}
.font-centered {
  text-align: center;
}
.font-bold {
  font-weight: 500;
}
.font-bolder {
  font-weight: 700;
}
.font-no-decoration {
  text-decoration: none !important;
}
.font-color-primary {
  color: #0f172a;
}
.font-color-secondary {
  color: #64748B;
}
.font-color-tertiary {
  color: #c2c2c2;
}
.font-color-border {
  color: #c0c2c5;
}
.font-color-white {
  color: white;
}
.font-color-highlight {
  color: #3278f5;
}
.font-color-highlight-dark {
  color: #366dc7;
}
.dark-text {
  color: white;
}
.white-text {
  color: white;
}
.font-white-f {
  color: white !important;
}
.font-size-tiny {
  font-size: 11px;
}
@media screen and (max-height: 600px) {
  .font-size-tiny {
    font-size: 11px;
  }
}
@media screen and (min-height: 1000px) {
  .font-size-tiny {
    font-size: 12px;
  }
}
.font-size-very-small {
  font-size: 12px;
}
@media screen and (max-height: 600px) {
  .font-size-very-small {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .font-size-very-small {
    font-size: 13px;
  }
}
.font-size-smaller {
  font-size: 12px;
}
@media screen and (max-height: 600px) {
  .font-size-smaller {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .font-size-smaller {
    font-size: 13px;
  }
}
.font-size-small {
  font-size: 13px;
}
@media screen and (max-height: 600px) {
  .font-size-small {
    font-size: 12px;
  }
}
@media screen and (min-height: 1000px) {
  .font-size-small {
    font-size: 14px;
  }
}
.font-size-standard {
  font-size: 14px;
}
@media screen and (max-height: 600px) {
  .font-size-standard {
    font-size: 13px;
  }
}
@media screen and (min-height: 1000px) {
  .font-size-standard {
    font-size: 14px;
  }
}
.font-size-above-standard {
  font-size: 15px;
}
@media screen and (max-height: 600px) {
  .font-size-above-standard {
    font-size: 14px;
  }
}
@media screen and (min-height: 1000px) {
  .font-size-above-standard {
    font-size: 15px;
  }
}
.font-size-medium {
  font-size: 15px;
}
@media screen and (max-height: 600px) {
  .font-size-medium {
    font-size: 14px;
  }
}
@media screen and (min-height: 1000px) {
  .font-size-medium {
    font-size: 16px;
  }
}
.font-size-large {
  font-size: 16px;
}
@media screen and (max-height: 600px) {
  .font-size-large {
    font-size: 15px;
  }
}
@media screen and (min-height: 1000px) {
  .font-size-large {
    font-size: 17px;
  }
}
.font-size-larger {
  font-size: 17px;
}
@media screen and (max-height: 600px) {
  .font-size-larger {
    font-size: 16px;
  }
}
@media screen and (min-height: 1000px) {
  .font-size-larger {
    font-size: 18px;
  }
}
.font-size-very-large {
  font-size: 18px;
}
@media screen and (max-height: 600px) {
  .font-size-very-large {
    font-size: 17px;
  }
}
@media screen and (min-height: 1000px) {
  .font-size-very-large {
    font-size: 19px;
  }
}
.font-size-emphasized {
  font-size: 19px;
}
@media screen and (max-height: 600px) {
  .font-size-emphasized {
    font-size: 19px;
  }
}
@media screen and (min-height: 1000px) {
  .font-size-emphasized {
    font-size: 21px;
  }
}
.font-ellipses {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.font-no-wrap {
  white-space: nowrap;
}
.opacity-none {
  opacity: 0;
}
.opacity-max {
  opacity: 1;
}
.bg-transparent {
  background-color: transparent;
}
.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-tinted {
  background-color: rgba(0, 0, 0, 0.65);
}
.bg-white {
  background-color: white;
}
.bg-white-f {
  background-color: white !important;
}
.bg-primary {
  background-color: #1c1c1c;
}
.bg-secondary {
  background-color: #323c46;
}
.bg-surface {
  background-color: #e9ebef;
}
.bg-surface-light {
  background-color: #e9ebef;
}
.bg-cream {
  background-color: #f6f6f6;
}
.bg-light-gray {
  background-color: #f6f7fb;
}
.bg-lightest-gray {
  background-color: #fcfcfc;
}
.bg-gray {
  background-color: #878c97;
}
.bg-dark-gray {
  background-color: #373c41;
}
.bg-red {
  background-color: #ac2222;
}
.bg-dark-red {
  background-color: darkred;
}
.bg-yellow {
  background-color: #808036;
}
.bg-orange {
  background-color: #efa928;
}
.bg-warning {
  background-color: #ffffe2;
}
.bg-green {
  background-color: #11a311;
}
.bg-dark-green {
  background-color: darkgreen;
}
.highlight {
  background-color: #3278f5;
  color: white;
}
.bg-highlight-lightest {
  background-color: #eff7fb;
}
.hover-font-color-highlight:hover {
  color: #3278f5;
}
.hover-bg-highlight-lightest:hover {
  background-color: #eff7fb;
}
.bg-highlight-light {
  background-color: #e5f7ff;
}
.bg-highlight {
  background-color: #3278f5;
}
.bg-highlight-dark {
  background-color: #366dc7;
}
.h-min-0 {
  min-height: 0px;
}
.h-min-80 {
  min-height: 80px;
}
.h-40 {
  height: 40px;
}
.h-50 {
  height: 50px;
}
.square-40 {
  height: 40px;
  min-width: 40px;
}
.square-50 {
  height: 50px;
  min-width: 50px;
}
.w-20 {
  width: 20%;
}
.w-120px {
  width: 120px;
}
.w-160px {
  width: 160px;
  min-width: 160px;
}
.w-170px {
  width: 170px;
}
.w-180px {
  width: 180px;
}
.w-200 {
  width: 200px;
  min-width: 200px;
}
.w-240 {
  width: 240px;
}
.w-400 {
  width: 400px;
}
.w-min-100 {
  min-width: 100px;
}
.w-min-120 {
  min-width: 120px;
}
.w-min-200 {
  min-width: 200px;
}
.w-min-220 {
  min-width: 220px;
}
.w-min-300 {
  min-width: 300px;
}
.w-min-360 {
  min-width: 360px;
}
.w-max-320 {
  max-width: 320px;
}
.w-max-360 {
  max-width: 360px;
}
.w-max-420 {
  max-width: 420px;
}
.line-height-inherit {
  line-height: inherit;
}
.shadow {
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.06), 0 3px 16px rgba(0, 0, 0, 0.08), 0 4px 24px rgba(0, 0, 0, 0.1);
}
.shadow-dark {
  box-shadow: 0px 0px 8px 0px #373c41;
}
.no-shadow {
  box-shadow: none;
}
.grow-width {
  transition: width 1s;
}
@keyframes MoveUpAnimation {
  0% {
    margin-bottom: 0px;
  }
  100% {
    margin-bottom: 200px;
  }
}
.move-up {
  animation: MoveUpAnimation 1000ms forwards 1200ms;
}
.fade-in-below-move-up {
  opacity: 0;
  animation: fadeIn 1000ms forwards 2200ms;
}
.pushed-right {
  margin-left: auto;
}
.block-centered-vertically {
  vertical-align: middle;
}
.clickable {
  cursor: pointer;
}
.vertical-list > div:last-child {
  border-bottom: none;
}
.vertical-pane > * {
  margin-bottom: 12px;
}
.vertical-pane > *:last-child {
  margin-bottom: 0;
}
.horizontal-pane > * {
  margin-right: 12px;
}
.horizontal-pane > *:last-child {
  margin-right: 0;
}
.horizontal-pane-2 > * {
  margin-right: 8px;
}
.horizontal-pane-2 > *:last-child {
  margin-right: 0;
}
