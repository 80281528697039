/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

#root {
  height: 100vh;
}

html, body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    height: 100%;
    width: 100%;
    overflow: hidden;
    line-height: 1.285em;
    margin: 0px;
    padding: 0px;
}

button {
    font-family: inherit !important;
}

.loader {

}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

.project-today-toggle {
  padding: 0px 10px;
}

.project-today-toggle:hover {
  background-color: rgba(255, 255, 0, .2) !important;
}

.project-today-toggle.marked {
  background-color: rgba(255, 255, 0, .2);
  border: 1px solid gold;
}

.project-today-toggle.marked:hover {
  background-color: rgba(255, 255, 0, .2);
}